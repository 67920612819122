import i18n from "i18n"
import { connect } from "react-redux"
import React, { Component } from "react"
import PropTypes from "prop-types"
import { Route, Routes } from "react-router-dom"
import swal from "sweetalert"

import initializer from "QuorumGrassroots/widgets/ReusableComponents/WidgetWrappers/InitializeStoreWrapper"
import { selectWidgetSubmitted } from "QuorumGrassroots/widgets/selectors"
import { destroyWidgetSlice, resetSubmittedWidget } from "QuorumGrassroots/widgets/action-creators"

import { ThanksDisplay } from "QuorumGrassroots/widgets/ReusableComponents/ThanksDisplay/index"
import { withRouter } from "QuorumGrassroots/withRouter"
import {
    getGamificationAchievementIcon,
    getIndexRoute,
    simpleReplacePlaceholders,
    getThanksSocialButtonProps,
    isSafari,
} from "QuorumGrassroots/helperFunctions"

/********************************************************************
 * The thankable wrapper indicates that this widget can be submitted.
 * This means that if some criteria specified by `shouldThankSelector`
 * is satisifed, we should show a thank you page.
 *
 * This will happen in one of two ways, dependent on whether or not
 * the widget is embedded (cannot go to another page).
 *
 * If the widget is not embedded:
 *   Get routed to a /current_path/thanks/ -- due to the magic of Router v4,
 *   this can send us to a thanks widget.
 *
 *   In the case `onRerouteThank` is passed an action creator, it will
 *   be called the moment we reroute. This is chiefly used to set 'submitted'
 *   back to false, in order to submit again if the user clicks back.
 *
 * If the widget is embedded:
 *   Instead of routing to /thanks/, just swap out the WidgetComponent
 *   for the thanks display.
 *
 * We'd like to reroute when possible, as this allows the user to be
 * able to access both the thanks page and the widget page when clicking back.
 ********************************************************************/

export class ThankableWrapper extends Component {
    static propTypes = {
        isEmbedded: PropTypes.bool.isRequired,
        params: PropTypes.object.isRequired,
        redirectDetails: PropTypes.object,
        useWidgetStyling: PropTypes.bool,
        // from connect
        shouldThank: PropTypes.bool.isRequired,
        thanksProps: PropTypes.object.isRequired,
        onRerouteThank: PropTypes.func,
        onThankUnmount: PropTypes.func,
        widget: PropTypes.func.isRequired,
    }

    componentWillUnmount() {
        if (this.props.shouldThank && this.props.onThankUnmount) {
            this.props.onThankUnmount(this.props.uniqueWidgetId)
        }
    }

    componentDidUpdate(oldProps) {
        // if our thanks status has changed, we now need to consider a few
        // things.
        //
        // 1. Is there a re-route link?
        // 2. Are we able to re-route? (Embedded widgets should not be allowed to
        //    re-route!)
        // 3. Should we route to the /thanks URL or should we stay put?
        const shouldThankChanged = !oldProps.shouldThank && this.props.shouldThank

        if (shouldThankChanged && !this.props.isEmbedded) {
            const { redirectDetails } = this.props.thanksProps

            // if we have a redirect upon thanking, we should send them off
            // to the next page.
            if (redirectDetails && redirectDetails.isInternal) {
                this.props.navigate(redirectDetails.url)
            } else if (redirectDetails) {
                swal({ icon: "success", title: "Thank you! You are now being redirected..." })
                if (isSafari()) {
                    window.open(redirectDetails.url, "_self")
                } else {
                    window.location.assign(redirectDetails.url)
                }
            } else if (this.props.location.search) {
                this.props.navigate(
                    // We pass the query string through, so preview and frameless are passed in the url of the next page
                    `thanks/${this.props.location.search}`,
                    { state: this.props.location.state },
                )
            } else {
                this.props.navigate("thanks", { state: this.props.location.state })
            }
        }
    }

    renderRedirectThanks() {
        const Widget = this.props.widget

        return (
            <Routes>
                <Route
                    path={"thanks"}
                    element={
                        <ThanksDisplay
                            uniqueWidgetId={this.props.uniqueWidgetId}
                            canRedirect={!this.props.isEmbedded}
                            useWidgetStyling={this.props.useWidgetStyling}
                            resetSubmittedWidget={this.props.onRerouteThank}
                            {...this.props.thanksProps}
                        />
                    }
                />
                <Route index element={<Widget {...this.props} isThankable />} />
            </Routes>
        )
    }

    renderEmbeddedThanks() {
        const Widget = this.props.widget

        return !this.props.shouldThank ? (
            <Widget {...this.props} isThankable />
        ) : (
            <ThanksDisplay
                uniqueWidgetId={this.props.uniqueWidgetId}
                canRedirect={!this.props.isEmbedded}
                useWidgetStyling={this.props.useWidgetStyling}
                resetSubmittedWidget={this.props.onRerouteThank}
                {...this.props.thanksProps}
            />
        )
    }

    render() {
        const Widget = this.props.widget

        // if a form specifically specifies do not thank, don't bother setting up anything.
        // If the form has a redirect on successful submit, also don't bother with anything.
        // (If you're embedded, you can't redirect, so you just have to settle for seeing a
        // thanks page.)
        if (this.props.thanksProps.redirectDetails && !this.props.isEmbedded) {
            return <Widget {...this.props} isThankable />
        }

        // Time for the fun thanks stuff!
        return this.props.isEmbedded ? this.renderEmbeddedThanks() : this.renderRedirectThanks()
    }
}

/**
 * A method to create a thankable component. Takes in certain selectors, gives back
 * a wrapped component wherein the widget gets rendered with a lot of other logic.
 */

export const thankable =
    ({ shouldThankSelector, thanksPropsSelector, onRerouteThank, onThankUnmount }) =>
    (Widget) => {
        const mapStateToProps = (state, props) => ({
            shouldThank: shouldThankSelector(state, props),
            thanksProps: thanksPropsSelector(state, props),
        })

        const actions = { onRerouteThank, onThankUnmount }

        const ConnectedThankable = withRouter(connect(mapStateToProps, actions)(ThankableWrapper))
        const wrapper = (props) => <ConnectedThankable {...props} widget={Widget} />
        // we're making this additional component so that we can test
        // ThankableWrapper without having to go through a bajillion connections.
        return wrapper
    }

/**
 * Create a thankable widget that has the defaults:
 *    - The submit page is triggered when the widget's "submitted" is set to true.
 *      This is set via the `successfullySubmittedWidget` action-creator.
 *    - The widget slice will destroy itself on unmount.
 *    - On rerouting to a thank you page, the submitted status will be reset to false.
 *      This is so a user can click back, go back to the widget, and submit again,
 *      if they so choose.
 *
 * @param  {Component} component
 * @param  {function}
 *
 * @return {Component}
 */
export const createStandardThankableWidget = (component, thanksPropsSelector) => {
    const initializedWidget = initializer(component)

    return thankable({
        shouldThankSelector: selectWidgetSubmitted,
        thanksPropsSelector: thanksPropsSelector || ((state, props) => ({ text: props.t("thank_you") })),
        onThankUnmount: destroyWidgetSlice,
        onRerouteThank: resetSubmittedWidget,
    })(initializedWidget)
}

export const getReduxlessThanksDisplayProps = (actionCenterSettings, campaign, isEmbedded, pointsEarned) => {
    const indexRoute = getIndexRoute(
        window.index_route,
        window.permissions,
        DjangIO.app.grassroots.models.GrassrootsRegistrationPage.idFromResourceUri(
            actionCenterSettings.default_registration_page,
        ),
    )
    const buttonDetails = [
        !campaign.block_action_center && {
            label: i18n.t("campaign.thanks.back.homepage"),
            link: indexRoute,
            type: DjangIO.app.grassroots.types.GrassrootsNavigationBarButtonType.single_page.value,
            dataCy: "homepage",
        },
    ].filter((button) => Boolean(button))

    const text =
        simpleReplacePlaceholders(campaign.thank_you_message, window.userdata) ||
        simpleReplacePlaceholders(actionCenterSettings.default_thank_you_message, window.userdata) ||
        i18n.t("campaign.thanks.text")
    const socialLinks =
        campaign.allow_sharing &&
        getThanksSocialButtonProps(campaign, actionCenterSettings, window.organization, window.organization_design)

    return {
        canRedirect: !isEmbedded,
        useWidgetStyling: false,
        achievementIcon: getGamificationAchievementIcon(actionCenterSettings.gamification_achievement_icon_type),
        buttonDetails: buttonDetails,
        currentTierIcon:
            actionCenterSettings.should_use_custom_stats_icon && actionCenterSettings?.gamification_tiers[1].imagePath,
        isCampaignPage: true,
        pointsEarned: pointsEarned,
        socialDetails: socialLinks,
        text: text,
    }
}
