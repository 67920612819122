import styled, { css } from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const CardSquare = styled.div`
    width: 50%;
`
export const CardSquareInner = styled.div`
    // TODO delete !important after we drop the old .styl files
    background-color: ${colors.White} !important;
    display: flex;
    flex-direction: column;
    height: 100% !important;

    ${props => props.isSelected && css`
        // TODO delete !important after we remediate SquareInlinePresentation.styl
        border: thin solid ${colors.QuorumBlue} !important;
        outline: thin solid ${colors.QuorumBlue};
    `}

    &:hover {
        background-color: ${colors.violet0} !important;
    }
`

export const CardSquareInnerAnchor = styled.a`
    flex-grow: 1;

    ${props => (props.isHighlightable) && css`
        pointer-events: none;
        user-select: none;
    `}
`

export const CardSquareContent = styled.div`
    flex-grow: 1;

    // TODO delete !important after we drop the old .styl files
    margin-left: 1px !important;
`

export const LineItem = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 11px;

    &:not(:last-child) {
        margin-right: 20px;
    }

    &:last-of-type {
        margin-bottom: 18px;
    }

    ${props => (props.isLink) && css`
        ${rules.CardStyle}
        padding: 4px 7px 5px 3px;
    `}
`

export const LineLabel = styled.span`
    border-bottom: thin solid transparent;
    color: ${colors.gray7};
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;

    ${props => (props.fa5Icon) && css`
        margin-left: 6px;
    `}

    ${props => (props.isLink) && css`
        ${rules.weight.medium}

        &:hover {
            border-bottom: thin dotted black;
        }
    `}
`

export const RoleToggletipOuterContainer = styled.div`
    display: inline-block;
    height: 1.187rem;

    button {
        // match the 19px line-height of the text
        height: 1.187rem;
        width: .5rem;
    }
`

export const RoleToggletipContainer = styled.div`
    > :first-child {
        transform: translate(-166px,30px);
    }
    > :first-child > :first-child {
        text-align: left;
    }
    > :first-child > :first-child > ul:first-child {
        line-height: 1.5rem;
        margin-top: .5rem;
        padding: 0 1.5rem 0 2rem;
    }
`

export const CardSquareIcon = styled.div`
    float: right;
    // TODO delete !important after we drop the old .styl files
    padding: 1px !important;
`

export const OptionsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
    margin-bottom: 3px;
`

export const OptionsButtonContainer = styled.div`
`

export const AdderComponent = styled.div`
    position: absolute;
    margin-top: 3px;
    min-width: 200px;
    z-index: 1;
`

export const InlineText = styled.div`
`

export const FirstLine = styled.h2`
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color: ${colors.gray9};
    display: flex;
    font-size: 20px;
    font-weight: 500 !important;
    line-height: normal;
    margin-bottom: 18px;
    // TODO delete !important after we remediate SquareInlinePresentation.styl
    margin-top: 10px !important;
    max-height: 55px;
    overflow: hidden;
    padding-left: 0px;
    padding-right: 5px;
    text-overflow: ellipsis;
`

export const IconNameContainer = styled.div`
    border: 3px solid ${colors.QuorumGrey3};
    padding: 15px;
    border-radius: 100%;
    margin-right: 11px;
    margin-top: 11px;
`

export const OptionTooltipPositioner = styled.div`
    & > :first-child {
        top: 0;
        left: calc(100% + 5px);
        min-width: 100px;
    }
`

export const OptionTooltipContainer = styled.div`
    position: relative;

    :not(&:hover) {
        ${OptionTooltipPositioner} {
            & > :first-child {
                opacity: 0%;
                visibility: hidden;
                transition-duration: ${rules.TransitionShort};
            }
        }
    }

    &:hover {
        ${OptionTooltipPositioner} {
            & > :first-child {
                opacity: 100%;
                visibility: visible;
                transition-duration: ${rules.TransitionShort};
            }
        }
    }
    transition-duration: ${rules.TransitionShort};
`

export const PacDataTitle = styled.div`
    color: var(--Gray-7, #495057);
    font-family: Roboto;
    font-size: var(--Body-Medium-Size, 14px);
    font-style: normal;
    font-weight: 500;
    line-height: var(--Body-Medium-Line-Height, 20px);
    letter-spacing: var(--Body-Medium-Tracking, 0.25px);
    margin-bottom: 8px;
`

export const PacDivider = styled.div`
    margin: 8px 0;
    height: 1px;
    background-color: var(--Gray-3, #DEE2E6);
`
