// https://projects.invisionapp.com/d/main/#/console/21044500/448838460/preview

import React, { useRef, useState } from "react"
import PropTypes from "prop-types"

import * as colors from "Components/colors"
import * as S from "Components/Compounds/CardSquare/style"

import Icon from "Components/Atoms/Icon"
import Button from "Components/Molecules/Button"
import DropdownButton from "Components/Molecules/DropdownButton"
import Tooltip from "Components/Molecules/Tooltip/index"

import { useOnClickOutside } from "utils/hooks"

const CardSquare = (props) => {
    const [isToggletipVisible, setIsToggletipVisible] = useState(false)
    const toggletipContainerRef = useRef()


    const handleToggletipClick = (e) => {
        e.preventDefault()
        setIsToggletipVisible(!isToggletipVisible)
    }

    const handleClickOutside = () => {
        setIsToggletipVisible(false)
    }

    useOnClickOutside(toggletipContainerRef, handleClickOutside)

    const renderInnerIcon = () => {
        if (props.imagePath) {
            return (
                <img
                    className={props.imagePathClassName}
                    src={props.imagePath}
                    alt="Inline Icon"
                    onError={props.imagePathOnError}
                    style={{
                        ...props.imageStyle,
                    }}
                />
            )
        } else if (props.stateIcon) {
            return <i className={`supporter-top-left stateface stateface-${props.abbrev}`} />
        } else if (props.iconName) {
            return <i className={`inline-icon fa fa-fw fa-${props.iconName}`} />
        }

        return undefined
    }

    const renderIcon = () => {
        if (props.imagePath || props.stateIcon || props.iconName || props.abbrev) {
            return (
                <S.CardSquareIcon className="pcard-top-left">
                    <div className={props.iconContainerClassName}>{renderInnerIcon()}</div>
                </S.CardSquareIcon>
            )
        }

        return undefined
    }

    const renderLineItem = (lineItem) => {
        return (
            <S.LineItem isLink={lineItem.id && lineItem.profile}>
                {lineItem.fa5Icon && (
                    <Icon
                        color={colors.gray5}
                        icon={lineItem.fa5Icon}
                        iconFamily={lineItem.fa5IconFamily}
                        key={lineItem.fa5Icon}
                        size={"1x"}
                    />
                )}
                <S.LineLabel fa5Icon={lineItem.fa5Icon} isLink={lineItem.id && lineItem.profile}>
                    {lineItem.value}
                    {lineItem.valueTooltip && (
                        <S.RoleToggletipOuterContainer
                            ref={toggletipContainerRef}
                            onClick={(event) => event.stopPropagation()}
                        >
                            {isToggletipVisible && (
                                <S.RoleToggletipContainer>
                                    <Tooltip
                                        text={lineItem.valueTooltip}
                                        fixedWidth="22rem"
                                        maxdWidth="22rem"
                                        placement="bottom"
                                    />
                                </S.RoleToggletipContainer>
                            )}
                            <Button
                                aria-label="All roles"
                                leftIcon="info-circle"
                                leftIconFamily="far"
                                leftIconStyle={{ color: colors.gray7 }}
                                onClick={handleToggletipClick}
                                type="borderless"
                            />
                        </S.RoleToggletipOuterContainer>
                    )}
                </S.LineLabel>
            </S.LineItem>
        )
    }

    const renderOption = (option) => {
        const OptionComponent = (
            option.isDropdown
                ? (
                    <DropdownButton
                        isTiny
                        leftIcon={option.fa5_icon}
                        leftIconFamily={option.fa5_icon_family}
                        leftIconSize="14px"
                        menuOptions={option.menuOptions}
                        text={option.label}
                        type="outlined"
                    />
                )
                : (
                    <S.OptionsButtonContainer>
                        <Button
                            forceActive={option.tip_text === props.adderType}
                            href={option.href}
                            isForm
                            isTiny
                            leftIcon={option.fa5_icon}
                            leftIconFamily={option.fa5_icon_family}
                            onClick={option.callback}
                            type="outlined"
                            style={{
                                width: "30px",
                            }}
                        />
                        {
                            (
                                props.AdderComponent &&
                                option.tip_text === props.adderType
                            ) &&
                                <S.AdderComponent>
                                    { props.AdderComponent }
                                </S.AdderComponent>
                        }
                    </S.OptionsButtonContainer>
                )
        )
        return option.tip_text
            ? (
                <S.OptionTooltipContainer>
                    {OptionComponent}
                    <S.OptionTooltipPositioner>
                        <Tooltip
                            isTiny
                            placement="right"
                            text={option.tip_text}
                        />
                    </S.OptionTooltipPositioner>
                </S.OptionTooltipContainer>
            )
            : OptionComponent
    }

    return (
        <S.CardSquare
            className={props.cardSquareClassName}
            data-auto-cy="CompoundCardSquare"
            data-cy={props.cardSquareDataCy}
        >
            <S.CardSquareInner
                className={props.cardSquareInnerClassName}
                data-cy={props.cardSquareInnerDataCy}
                isSelected={props.isSelected}
                onClick={props.cardSquareInnerOnClick}
            >
                <S.CardSquareInnerAnchor
                    href={props.cardSquareInnerAnchorHref}
                    isHighlightable={props.isHighlightable}
                >
                    <S.CardSquareContent className={props.cardSquareContentClassName}>
                        {renderIcon()}
                        {props.firstLineText && (
                            <S.FirstLine>
                                <div>{props.firstLineText}</div>
                                {props.pacBadge}
                                {props.firstLineIcon}
                            </S.FirstLine>
                        )}
                        {props.secondLine && renderLineItem(props.secondLine)}
                        {props.thirdLine && renderLineItem(props.thirdLine)}
                        {props.fourthLine && renderLineItem(props.fourthLine)}
                        {props.fifthLine && renderLineItem(props.fifthLine)}
                        {props.sixthLine && renderLineItem(props.sixthLine)}
                        {props.pacData && (
                            Object.values(props.pacData).some(value => value !== null && value !== undefined) && (
                                <>
                                    <S.PacDivider />
                                    <S.PacDataTitle>PAC information</S.PacDataTitle>
                                    {props.pacData.email && renderLineItem(props.pacData.email)}
                                    {props.pacData.phone && renderLineItem(props.pacData.phone)}
                                    {props.pacData.address && renderLineItem(props.pacData.address)}
                                </>
                            )
                        )}
                        {props.cardSquareContentChildren}
                    </S.CardSquareContent>
                </S.CardSquareInnerAnchor>

                {props.options && !props.isHighlightable && (
                    <S.OptionsContainer
                        // we don't want clicking on an adder component to redirect us to a profile
                        onClick={(event) => event.stopPropagation()}
                    >
                        {props.options
                            // we set option.display as a conditional value
                            // for a small subset of object entries in
                            // shared/search/inline-selectors/inline-options-selectors.js

                            // because option.display is usually undefined, we explicitly
                            // check to see if the value is false rather than if it is always truthy
                            .filter((option) => option.display !== false)
                            .map((option) => renderOption(option))}
                    </S.OptionsContainer>
                )}
            </S.CardSquareInner>
            {props.children}
        </S.CardSquare>
    )
}

CardSquare.propTypes = {
    // may be IssueAdder or ListAdder
    // used to add/remove issues and lists to the object this card represents in search
    AdderComponent: PropTypes.element,
    children: PropTypes.arrayOf(PropTypes.element),

    // WIP: these props will change once we integrate all of the old
    // app/static/stylus/SquareInlinePresentation.styl
    // styles and delete the {x}ClassName props

    // TODO: alphbetize these after the props being passed here are stable
    cardSquareClassName: PropTypes.string,
    cardSquareDataCy: PropTypes.string,
    cardSquareInnerClassName: PropTypes.string,
    cardSquareInnerDataCy: PropTypes.string,
    cardSquareInnerOnClick: PropTypes.func,
    cardSquareInnerAnchorHref: PropTypes.string,
    iconContainerClassName: PropTypes.string,
    imagePath: PropTypes.string,
    imagePathClassName: PropTypes.string,
    imagePathOnError: PropTypes.func,
    imageStyle: PropTypes.object,
    stateIcon: PropTypes.string,
    abbrev: PropTypes.string,
    iconName: PropTypes.string,
    cardSquareContentClassName: PropTypes.string,
    cardSquareContentChildren: PropTypes.arrayOf(PropTypes.element),
    firstLineIcon: PropTypes.element,
    firstLineText: PropTypes.string,
    secondLine: PropTypes.string,
    thirdLine: PropTypes.string,
    fourthLine: PropTypes.string,
    fifthLine: PropTypes.string,
    sixthLine: PropTypes.string,

    // S.Options
    isHighlightable: PropTypes.bool,
    isSelected: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.object),

}

export default CardSquare
