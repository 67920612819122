/* eslint-disable */
//

import { fromJS } from "immutable"
import moment from "moment"
import {
    checkLineBreakHtml,
    checkParseAlertString,
    humanFileSize,
    getCreatedDate,
    generateMediaUrl,
    simpleLineBreak,
    simpleLineBreaks,
    stringContainsHTMLMarkup,
    generateStaticUrl,
    lineBreaks,
    replaceAtMentionsWithTags,
    replaceFeedItemMention,
    createDataSections,
    toDateTimeString,
} from "shared/imports/sharedHelperFunctions"

import { BrandColors, MEDIA_TERMS_AND_CONDITIONS_FOOTER, BLANK_AVATAR_PATH } from "shared/imports/sharedConstants"
import { isAllRegionsExceptUS } from "shared/imports/regionConstants"
import { checkPermission, checkHasPermission } from "shared/imports/permissionFunctions"
import { GrassrootsInlineRightSection } from "@/pages/search/grassroots/GrassrootsInlineRightSection"

import { getImage } from "shared/search/inline-selectors/personInlineSelectors"
import {
    generateTransactionHyperlink,
    getTransactionEditUrl,
    isDisbursementTransaction,
    isReceiptTransaction,
} from "shared/pac/helperFunctions"
import { isFeatureEnabled } from "shared/featureflags/helperFunctions"
import { sanitizeUserCommentTextHtml } from "shared/forms/helperFunctions"

import { shareableSelectShouldShowFrame } from "shared/profiles/article/article-selectors"
import { sendUserActionPost } from "shared/user-actions/user-actions-action-creators"
import { NOTE_BILL_TRUNCATION_LIMIT } from "shared/search/constants"
import { mobileBreakpoint } from "app/static/frontend/design-constants"
import paths from "app/static/frontend/imports/desktopPaths"
import { DjangIOEnumTypes } from "_djangio_enum_types"

const stateKeys = require("shared/stateface.json")

const getIsMobile = () => window.innerWidth <= mobileBreakpoint

/* *****************************************************************************
 *
 *   Inline Text Selectors. Document and Person inlines are in separate files, as
 *   they are too complex. If you plan on changing these selectors, make sure to
 *   update download-center/containers/inlines/selectors as well.
 *
 ****************************************************************************** */

const getIssues = (issues) =>
    issues &&
    issues.toJS &&
    issues
        .toJS()
        .map((issue) => (issue.parent__name ? `${issue.name} (${issue.parent__name})` : issue.name))
        .join(", ")

export const getSecondLineIcon = (key) => {
    switch (key) {
        case "status":
            return {
                fa5Icon: "dot-circle",
                fa5IconFamily: "far",
            }
        case "type":
            return {
                fa5Icon: "file-alt",
                fa5IconFamily: "fal",
            }
        case "date":
            return {
                fa5Icon: "clock",
                fa5IconFamily: "fal",
            }
        case "organizations":
            return {
                fa5Icon: "building",
                fa5IconFamily: "fal",
            }
        case "issues":
            return {
                fa5Icon: "thumbtack",
                fa5IconFamily: "fal",
            }
        case "post":
            return {
                fa5Icon: "sticky-note",
                fa5IconFamily: "far",
            }
        case "mail":
            return {
                fa5Icon: "envelope",
                fa5IconFamily: "fal",
            }
        case "info":
            return {
                fa5Icon: "info-circle",
                fa5IconFamily: "fal",
            }
        case "ballot":
            return {
                fa5Icon: "ballot-check",
                fa5IconFamily: "fal",
            }
        case "committee":
            return {
                fa5Icon: DjangIO.app.models.QuorumDataType.committee.fa5_icon,
                fa5IconFamily: "fal",
            }
        case "comments":
            return {
                fa5Icon: "comments",
                fa5IconFamily: "fal",
            }
        case "podium":
            return {
                fa5Icon: "podium",
                fa5IconFamily: "far",
            }
        case "phone":
            return {
                fa5Icon: "phone",
                fa5IconFamily: "fal",
            }
        case "map-marker":
            return {
                fa5Icon: "map-marker",
                fa5IconFamily: "fal",
            }
        case "map-marker-alt":
            return {
                fa5Icon: "map-marker-alt",
                fa5IconFamily: "far",
            }
        case "upload":
            return {
                fa5Icon: "upload",
                fa5IconFamily: "far",
            }
        case "folder":
            return {
                fa5Icon: "folder",
                fa5IconFamily: "far",
            }
        case "pencil-alt":
            return {
                fa5Icon: "pencil-alt",
                fa5IconFamily: "far",
            }
        case "building":
            return {
                fa5Icon: "building",
                fa5IconFamily: "far",
            }
        case "envelope":
            return {
                fa5Icon: "envelope",
                fa5IconFamily: "far",
            }
        case "usd-square":
            return {
                fa5Icon: "usd-square",
                fa5IconFamily: "far",
            }
        case "star":
            return {
                fa5Icon: "star",
                fa5IconFamily: "far",
            }
        case "gavel":
            return {
                fa5Icon: "gavel",
                fa5IconFamily: "fal",
            }
        case "globe":
            return {
                fa5Icon: "globe",
                fa5IconFamily: "fal",
            }
        case "map":
            return {
                fa5Icon: "map",
                fa5IconFamily: "far",
            }
        case "address-card":
            return {
                fa5Icon: "address-card",
                fa5IconFamily: "far",
            }
        case "bullhorn":
            return {
                fa5Icon: "bullhorn",
                fa5IconFamily: "far",
            }
        case "box-ballot":
            return {
                fa5Icon: "box-ballot",
                fa5IconFamily: "far",
            }
        case "newspaper":
            return {
                fa5Icon: "newspaper",
                fa5IconFamily: "far",
            }
        case "thumbs-up":
            return {
                fa5Icon: "thumbs-up",
                fa5IconFamily: "fal",
            }
        case "share":
            return {
                fa5Icon: "share",
                fa5IconFamily: "fal",
            }
        case "pen":
            return {
                fa5Icon: "pen-alt",
                fa5IconFamily: "far",
            }
        case "user-circle":
            return {
                fa5Icon: "user-circle",
                fa5IconFamily: "far",
            }
        case "calendar":
            return {
                fa5Icon: "calendar-day",
                fa5IconFamily: "far",
            }
        case "journalists":
            return {
                fa5Icon: "user",
                fa5IconFamily: "far",
            }
        case "issue-area":
            return {
                fa5Icon: "location",
                fa5IconFamily: "far",
            }
        default:
            return {
                fa5Icon: "",
                fa5IconFamily: "",
            }
    }
}

export const getDefinedFields = (datum, fields) =>
    fields.map((field) => datum.get(field)).filter((field) => Boolean(field))

export const getDefinedFieldsDS = (datum, fields) =>
    fields
        .map((field) => ({
            ...getSecondLineIcon("info"),
            value: datum.get(field),
        }))
        .filter((field) => Boolean(field.value))

export const parseDate = (date) =>
    toDateTimeString(date, null, true, false, false, Userdata.has_international_region, true)
export const parseTime = (date) => moment(date, "HH:mm:ss").format("h:mm:ss A")
export const parseDateUS = (date) => moment(date, "MM-DD-YYYY").format("MMMM Do, YYYY")
export const parseDateTime = (date) =>
    toDateTimeString(date, null, false, false, false, Userdata.has_international_region, true)

const parseEnumValues = (enumArr, enumQDT, label) => {
    const enumArrJS = enumArr.toJS().filter((element) => element !== undefined && element !== null)
    const typeArr = []

    if (enumArrJS.length) {
        enumArrJS.forEach((typeInt) => {
            const enumInfo = []
            const enumObject = enumQDT.by_value(typeInt)
            const enumLabel = enumObject[label]
            const enumValLabel = label === "abbrev" ? enumLabel.toUpperCase() : enumLabel

            if (enumObject && enumValLabel) {
                enumInfo.push(enumValLabel)

                if (enumQDT === DjangIO.app.models.Region) {
                    if (enumObject.value === DjangIO.app.models.Region.district_of_columbia.value) {
                        enumInfo.push("District")
                    } else if (enumObject.value === DjangIO.app.models.Region.federal.value) {
                        enumInfo.push("Federal")
                    } else if (enumObject.is_state_region) {
                        enumInfo.push("State")
                    } else if (enumObject.is_local_region) {
                        enumInfo.push("Local")
                    }
                }

                typeArr.push(enumInfo.join(" "))
            }
        })
    }

    return typeArr.length ? typeArr.join(", ") : undefined
}

export const genericSelectorCreator = (selector) => (datum) => ({
    iconName: selector.iconName,

    firstLineData: datum.get(selector.firstField),
    secondLineData: datum.get(selector.secondField),
    thirdLineData: datum.get(selector.thirdField),
})

export const genericStateSelectorCreator = (selector) => (datum) => {
    const getStateAbbrev = () => {
        const stateAbbrev = datum.get(selector.stateAbbrevField)

        if (stateKeys[stateAbbrev]) {
            return stateAbbrev.toLowerCase().replace(/^\s+|\s+$/g, "")
        }
        return "us"
    }

    return {
        abbrev: getStateAbbrev(),
        stateIcon: !isAllRegionsExceptUS && (stateKeys[datum.get(selector.stateAbbrevField)] || stateKeys.US),

        firstLineData: datum.get(selector.firstField),
        secondLineData: datum.get(selector.secondField),
        thirdLineData: datum.get(selector.thirdField),
        isHalfWidth: true,
    }
}

export const billTypeSelector = (datum) => datum.get("bill_type")

export const billInlineSelector = (datum) => {
    const getStatusLabel = () => {
        const status = DjangIO.app.bill.status.BillStatus.by_value(datum.get("current_status"))

        switch (datum.get("region")) {
            case DjangIO.app.models.Region.federal.value:
            case DjangIO.app.models.Region.eu.value:
                return status.label
            case DjangIO.app.models.Region.district_of_columbia.value:
                return status.state_dc_label
            case DjangIO.app.models.Region.nevada.value:
            case DjangIO.app.models.Region.new_jersey.value:
            case DjangIO.app.models.Region.new_york.value:
            case DjangIO.app.models.Region.wisconsin.value:
            case DjangIO.app.models.Region.california.value:
                return status.state_other_label
            default:
                return status.state_label
        }
    }
    const currentStatusLabel = getStatusLabel()
    const getEnactedText = () =>
        datum.get("current_status_date")
            ? `${currentStatusLabel} on ${parseDate(datum.get("current_status_date"))}`
            : currentStatusLabel
    const getEnactedClass = () =>
        datum.get("current_general_status") === DjangIO.app.bill.status.GeneralBillStatus.enacted.value ||
        datum.get("current_general_status") === DjangIO.app.bill.status.GeneralBillStatus.adopted.value

    const getThirdLine = () => {
        if (datum.get("region") === DjangIO.app.models.Region.federal.value && datum.get("sponsor_name")) {
            return `Sponsored by ${datum.get("sponsor_name")}`
        }

        const lastActionText = datum.get("last_action_text")
        if (!currentStatusLabel?.includes(lastActionText)) {
            return `${lastActionText}`
        }

        return undefined
    }

    const getSecondLineDS = () => {
        const secondLine = []

        const statusLine = []
        const enactedText = getEnactedText()
        if (enactedText) {
            statusLine.push(enactedText)
        }

        const thirdLine = getThirdLine()
        if (thirdLine) {
            statusLine.push(thirdLine)
        }

        if (getIsMobile()) {
            enactedText &&
                secondLine.push({
                    ...getSecondLineIcon("status"),
                    value: enactedText,
                })
        } else {
            secondLine.push({
                ...getSecondLineIcon("status"),
                value: statusLine.join(" | "),
            })

            const issues = getIssues(datum.getIn("_extra", "issues"))
            if (issues) {
                secondLine.push({
                    ...getSecondLineIcon("issues"),
                    value: issues,
                })
            }
        }

        return secondLine
    }

    const snippets = datum.get("snippets")
    let currentGeneralStatus =
        datum.get("current_general_status") &&
        (DjangIO.app.bill.status.GeneralBillStatus.by_value(datum.get("current_general_status"))
            .label.toLowerCase()
            .includes("unknown")
            ? 0
            : datum.get("current_general_status"))
    let currentGeneralStatusEnum = DjangIO.app.bill.status.GeneralBillStatus.region_supported_items()
        .filter((status) => {
            if (datum.get("bill_type") === DjangIO.app.bill.models.BillType.nomination.value) {
                return [
                    DjangIO.app.bill.status.GeneralBillStatus.received.label,
                    DjangIO.app.bill.status.GeneralBillStatus.left_committee.label,
                    DjangIO.app.bill.status.GeneralBillStatus.confirmed.label,
                ].includes(status.label)
            } else if (datum.get("bill_type") === DjangIO.app.bill.models.BillType.eu_initiative.value) {
                return [
                    DjangIO.app.bill.status.GeneralBillStatus.eu_initiative_in_preparation.label,
                    DjangIO.app.bill.status.GeneralBillStatus.eu_initiative_commission_adoption.label,
                ].includes(status.label)
            }
            return ![
                DjangIO.app.bill.status.GeneralBillStatus.unknown.label,
                DjangIO.app.bill.status.GeneralBillStatus.eu_unknown.label,
                DjangIO.app.bill.status.GeneralBillStatus.eu_initiative_in_preparation.label,
                DjangIO.app.bill.status.GeneralBillStatus.eu_initiative_commission_adoption.label,
                DjangIO.app.bill.status.GeneralBillStatus.failed.label,
                DjangIO.app.bill.status.GeneralBillStatus.received.label,
                DjangIO.app.bill.status.GeneralBillStatus.left_committee.label,
                DjangIO.app.bill.status.GeneralBillStatus.confirmed.label,
                DjangIO.app.bill.status.GeneralBillStatus.returned.label,
                DjangIO.app.bill.status.GeneralBillStatus.withdrawn.label,
            ].includes(status.label)
        })
        .sort(DjangIO.valueSort)

    if (currentGeneralStatus && Userdata.isGlobalMode()) {
        const generalStatus = DjangIO.app.bill.status.GeneralBillStatus.by_value(currentGeneralStatus)
        const globalStatusEnum = DjangIO.app.bill.status.GlobalBillStatus
        const generalStatusEnum = DjangIO.app.bill.status.GeneralBillStatus
        const globalStatusMap = {
            [globalStatusEnum.introduced.value]: generalStatusEnum.global_introduced,
            [globalStatusEnum.in_progress.value]: generalStatusEnum.global_in_progress,
            [globalStatusEnum.adopted.value]: generalStatusEnum.global_adopted,
        }

        currentGeneralStatus = globalStatusMap[generalStatus.global_status].value
        currentGeneralStatusEnum = [
            generalStatusEnum.global_introduced,
            generalStatusEnum.global_in_progress,
            generalStatusEnum.global_adopted,
        ]
    }

    return {
        href: `/bill/${datum.get("id")}/`,
        currentGeneralStatus,
        currentGeneralStatusEnum,
        model: DjangIO.app.models.QuorumDataType.bill.value,
        region: !(
            Userdata.isOnlyFederalMode() &&
            DjangIO.app.models.Region.by_value(datum.get("region")).region_name === "Federal"
        )
            ? DjangIO.app.models.Region.by_value(datum.get("region")).region_name
            : undefined,
        showRegion: true,

        firstLineData: datum.get("title"),
        enactedText: getEnactedText(),
        thirdLineData: getThirdLine(),
        hasBeenEnacted: getEnactedClass(),
        squashThirdLine: true,
        firstLineIsHtml: stringContainsHTMLMarkup(datum.get("title")),
        thirdLineIsHtml: snippets && snippets.size > 0,
        snippets: snippets && snippets.size > 0 ? snippets.toJS() : undefined,

        secondLineDS: getSecondLineDS(),
        timelineEnum: DjangIO.app.bill.status.GeneralBillStatus,
        timelineStatuses: currentGeneralStatusEnum.map((currentGeneralStatusItem) => ({
            achieved: currentGeneralStatus >= currentGeneralStatusItem.value,
            status: currentGeneralStatusItem.value,
        })),
        ...(getIsMobile() && {
            iconName: "file-text-o",
        }),
    }
}

export const supporterInlineSelector = (datum) => {
    let iconName = DjangIO.app.models.QuorumDataType.supporter.icon.replace("fa-", "")

    if (Userdata.isOnlyEUMode()) {
        iconName = "user supporter-icon"
    }

    if (isAllRegionsExceptUS) {
        iconName = "user"
    }

    const definedFields = []

    const title = datum.get("title")
    if (title) {
        definedFields.push(title)
    }

    const publicOrgNames = datum.get("public_organization_names")
    if (publicOrgNames) {
        definedFields.push(publicOrgNames)
    }

    const address = datum.get("input_address")
    if (address) {
        definedFields.push(address)
    }

    const email = datum.get("email")
    if (email) {
        definedFields.push(`<a href='mailto:${email}'>${email}</a>`)
    }

    const phone = datum.get("formatted_primary_phone")
    if (phone) {
        definedFields.push(phone)
    }

    const definedFieldsDS = []

    if (!isFeatureEnabled("ff_ngg_pac_supporter_synced_contacts")) {
        const secondaryPhone = datum.get("formatted_secondary_phone")
        if (secondaryPhone) {
            definedFields.push(secondaryPhone)
            definedFieldsDS.push({
                ...getSecondLineIcon("phone"),
                value: secondaryPhone,
            })
        }

    }

    const countyName = datum.get("county_name")
    if (countyName) {
        definedFields.push(countyName)
    }

    const getStateAbbrev = () => {
        const stateAbbrev = datum.get("state_abbreviation")

        if (stateAbbrev) {
            return stateAbbrev.toLowerCase().replace(/^\s+|\s+$/g, "")
        }
        return "us"
    }

    const getStateIcon = () => {
        const stateIcon = stateKeys[datum.get("state_abbreviation")]
        const { PermissionType } = DjangIO.app.models

        // if it is a stakeholder only org user,
        // we only want to show the state iff it exists in the datum
        if (
            checkPermission(PermissionType.stakeholder) &&
            // this is the check we use in a couple other places in search
            // to ensure that it is a stakeholder only org
            !checkHasPermission([PermissionType.legislators, PermissionType.executives, PermissionType.staffers])
        ) {
            return stateIcon
            // outside of stakeholder, we only want to show the state/us icon if they are in the US
        } else if (!isAllRegionsExceptUS) {
            if (stateIcon) {
                return stateIcon
            }
            if (datum.get("country_name") == "US") {
                return stateKeys.US
            }
        }

        // in all other regions, we should instead render the iconName
        return undefined
    }

    const imageUrl =
        datum.get("image_url") &&
        (!datum.get("image_url").includes("img.fullcontact.com")
            ? generateMediaUrl(datum.get("image_url"))
            : datum.get("image_url"))

    let secondLineIsHtml = false
    let thirdLineIsHtml = false
    let fourthLineIsHtml = false
    let fifthLineIsHtml = false

    if (definedFields[0] && stringContainsHTMLMarkup(definedFields[0])) {
        secondLineIsHtml = true
    }
    if (definedFields[1] && stringContainsHTMLMarkup(definedFields[1])) {
        thirdLineIsHtml = true
    }
    if (definedFields[2] && stringContainsHTMLMarkup(definedFields[2])) {
        fourthLineIsHtml = true
    }
    if (definedFields[3] && stringContainsHTMLMarkup(definedFields[3])) {
        fifthLineIsHtml = true
    }

    const paccIndividualId = datum.get("pacc_individual_id")
    const pacData = datum.get("_extra")?.get("individuals_information")?.toJSON()

    const isPacContact = paccIndividualId && pacData?.pac_links.length > 0

    const pacDefineFields = []

    if (title) {
        definedFieldsDS.push({
            ...getSecondLineIcon("address-card"),
            value: title,
        })
    }

    const pubOrgNames = datum.get("public_organization_names")
    if (pubOrgNames) {
        definedFieldsDS.push({
            ...getSecondLineIcon("building"),
            value: pubOrgNames,
        })
    }

    if (address) {
        definedFieldsDS.push({
            ...(isFeatureEnabled("ff_new_card_square")
                ? getSecondLineIcon("map-marker")
                : getSecondLineIcon("map-marker-alt")),
            value: address,
        })
    }

    if (isFeatureEnabled("ff_ngg_pac_supporter_synced_contacts") && pacData?.address && address !== pacData?.address) {
            pacDefineFields.push({
                ...(isFeatureEnabled("ff_new_card_square")
                    ? getSecondLineIcon("map-marker")
                    : getSecondLineIcon("map-marker-alt")),
                value: pacData?.address,
            })
        }

    if (email) {
        definedFieldsDS.push({
            ...getSecondLineIcon("envelope"),
            isHtml: true,
            value: `<a href='mailto:${email}'>${email}</a>`,
        })
    }

    if (isFeatureEnabled("ff_ngg_pac_supporter_synced_contacts") && pacData?.pac_email && email !== pacData?.pac_email) {
        pacDefineFields.push({
            ...getSecondLineIcon("envelope"),
            isHtml: true,
            value: `<a href='mailto:${pacData?.pac_email}'>${pacData?.pac_email}</a>`,
        })
    }

    if (phone) {
        definedFieldsDS.push({
            ...getSecondLineIcon("phone"),
            value: phone,
        })
    }

    if (isFeatureEnabled("ff_ngg_pac_supporter_synced_contacts") && pacData?.pac_phone && phone !== pacData?.pac_phone) {
        pacDefineFields.push({
            ...getSecondLineIcon("phone"),
            value: pacData?.pac_phone,
        })
    }

    if (countyName) {
        definedFieldsDS.push({
            ...getSecondLineIcon("map"),
            value: countyName,
        })
    }

    if (window.innerWidth <= mobileBreakpoint) {
        return {
            iconName,
            imagePath: imageUrl,
            firstLineData: datum.get("name") || "Unnamed Contact",
            secondLineDS: definedFieldsDS.slice(0, 4),
        }
    }

    return {
        abbrev: getStateAbbrev(),
        href: `/contact/${datum.get("id")}/`,
        imagePath: imageUrl || generateStaticUrl(BLANK_AVATAR_PATH),
        iconName,
        stateIcon: getStateIcon(),
        model: DjangIO.app.models.QuorumDataType.supporter.value,

        firstLineData: datum.get("name") || "Unnamed Contact",
        secondLineData: definedFields[0],
        thirdLineData: definedFields[1],
        fourthLineData: definedFields[2],
        fifthLineData: definedFields[3],
        sixthLineData: definedFields[4],
        secondLineIsHtml,
        thirdLineIsHtml,
        fourthLineIsHtml,
        fifthLineIsHtml,

        secondLine: definedFieldsDS[0],
        thirdLine: definedFieldsDS[1],
        fourthLine: definedFieldsDS[2],
        fifthLine: definedFieldsDS[3],
        sixthLine: definedFieldsDS[4],

        isPacContact,
        pacData: {
            address: pacDefineFields[0],
            email: pacDefineFields[1],
            phone: pacDefineFields[2]
        },

        name: datum.get("name") || "Unnamed Contact",
    }
}

export const publicOrganizationInlineSelector = (datum) => {
    const { IntradoCoverage } = DjangIO.app.media_monitoring.types
    const { QuorumDataType } = DjangIO.app.models
    const { EntityType } = DjangIO.app.pac.types
    const { PublicOrganization } = DjangIO.app.person.models
    const { NewMajorOrganizationType, SectorType } = DjangIO.app.person.newtypes
    const { PublicOrganizationType } = DjangIO.app.person.types

    const isOutlet = datum.get("_ph_sector_type") === SectorType.media.value
    let secondLineIsHtml = false

    const getSecondLine = () => {
        const secondLine = []

        const publicOrganizationType = datum.get("organization_type")
        if (publicOrganizationType) {
            secondLine.push(PublicOrganizationType.by_value(publicOrganizationType).label)
        } else if (isOutlet) {
            secondLine.push("Media Outlet")
        }

        const majorOrganizationType = datum.get("_ph_major_organization_type")
        if (majorOrganizationType) {
            secondLine.push(NewMajorOrganizationType.by_value(majorOrganizationType).label)
        }

        const pacOrgType = datum.get("pac_org_type")
        if (pacOrgType) secondLine.push(EntityType.by_value(pacOrgType).label)

        const parentOrganizationName = datum.get("parent_organization_name")
        const parentOrganizationId =
            datum.get("parent_organization") && PublicOrganization.idFromResourceUri(datum.get("parent_organization"))
        if (parentOrganizationName && parentOrganizationId) {
            secondLine.push(
                `Parent Organization: <a href="/organization/${parentOrganizationId}/">${parentOrganizationName}</a>`,
            )
            secondLineIsHtml = true
        }

        return secondLine.join(" | ")
    }

    const getThirdLine = () => {
        // thirdLineData is for Outlets and Addresses
        const thirdLine = []

        if (isOutlet) {
            const coverage = datum.get("intrado_coverage")
            if (coverage) {
                thirdLine.push(`Coverage: ${IntradoCoverage.by_value(coverage).label}`)
            }

            const dma = datum.getIn(["_extra", "market_areas"])
            if (dma && dma.size) {
                thirdLine.push(
                    `DMA: ${dma
                        .map((dma) => dma.get("designated_market_area__name"))
                        .toJS()
                        .join(", ")}`,
                )
            }
        }

        const billingAddress = datum.get("billing_address")
        const shippingAddress = datum.get("shipping_address")
        // if just one address, format as "Address: 123 Some St ..."
        // if both, format as "Billing: 123 ... | Shipping: 456 ..."
        if (billingAddress) thirdLine.push(`${shippingAddress ? "Billing" : "Address"}: ${billingAddress}`)
        if (shippingAddress) thirdLine.push(`${billingAddress ? "Shipping" : "Address"}: ${shippingAddress}`)

        return thirdLine.join(" | ")
    }

    const getFourthLine = () => {
        // fourthLineData is for Outlets only
        if (!isOutlet) {
            return
        }

        const beats = datum.getIn(["_extra", "beats"])
        if (beats && beats.size) {
            return `Beats: ${beats
                .map((dma) => dma.get("beat__name"))
                .toJS()
                .join(", ")}`
        }
    }

    const imageProps = isOutlet
        ? {
              fa5IconFamily: "far",
              fa5IconName: "clipboard-user",
              imagePath: datum.get("s3_image_url"),
              imageBorderRule: { borderColor: BrandColors.ULTRAVIOLET },
          }
        : {
              iconName: QuorumDataType.public_organization.icon.replace("fa-", ""),
              imageUrlPath: datum.get("image_url"),
          }

    let secondLineDSIsHtml = false
    const getSecondLineDS = () => {
        const secondLine = []

        const publicOrganizationType = datum.get("organization_type")
        if (publicOrganizationType) {
            secondLine.push({
                ...getSecondLineIcon("building"),
                value: PublicOrganizationType.by_value(publicOrganizationType).label,
            })
        } else if (isOutlet) {
            secondLine.push({
                ...getSecondLineIcon("info"),
                value: "Media Outlet",
            })
        }

        const majorOrganizationType = datum.get("_ph_major_organization_type")
        if (majorOrganizationType) {
            secondLine.push({
                ...getSecondLineIcon("building"),
                value: NewMajorOrganizationType.by_value(majorOrganizationType).label,
            })
        }

        const pacOrgType = datum.get("pac_org_type")
        if (pacOrgType) {
            const label = EntityType.by_value(pacOrgType).label
            secondLine.push({
                ...getSecondLineIcon("organizations"),
                value: label,
            })
        }

        const parentOrganizationName = datum.get("parent_organization_name")
        const parentOrganizationId =
            datum.get("parent_organization") && PublicOrganization.idFromResourceUri(datum.get("parent_organization"))
        if (parentOrganizationName && parentOrganizationId) {
            secondLine.push({
                value: `Parent Organization: <a href="/organization/${parentOrganizationId}/">${parentOrganizationName}</a>`,
            })
            secondLineDSIsHtml = true
        }

        return secondLine
    }

    return {
        href: `/organization/${datum.get("id")}/`,
        ...imageProps,
        model: QuorumDataType.public_organization.value,

        firstLineData: datum.get("name", ""),
        secondLineData: getSecondLine(),
        secondLineIsHtml,
        thirdLineData: getThirdLine(),
        fourthLineData: getFourthLine(),

        secondLineDS: getSecondLineDS(),
        secondLineDSIsHtml,
    }
}

export const trackingDashboardInlineSelector = (datum) => {
    const qdt = DjangIO.app.models.QuorumDataType.by_value(datum.get("data_type"))
    return {
        iconName: DjangIO.app.models.QuorumDataType.project.icon.replace("fa-", ""),

        firstLineData: datum.get("name"),
        secondLineData: `${qdt && qdt.label ? qdt.label : ""}${qdt ? " " : ""}Tracking Board`,
        thirdLineData: datum.get("year") && `${datum.get("year")}`,
    }
}

export const issueManagementInlineSelector = (datum) => {
    let thirdLineIsHtml = false

    const getSecondLine = () => {
        const parent = datum.getIn(["_extra", "parent_name"])

        if (parent) {
            return `Sub-issue of ${parent}`
        }

        return "Primary Issue"
    }

    const getSecondLineDS = () => [
        {
            ...getSecondLineIcon("issues"),
            value: getSecondLine(),
        },
    ]

    const getThirdLine = () => {
        const description = datum.get("long_description")

        if (description) {
            if (stringContainsHTMLMarkup(description)) {
                thirdLineIsHtml = true
            }

            return description
        }

        return undefined
    }

    return {
        iconName:
            (datum.get("icon") || "").replace("fa-", "") ||
            DjangIO.app.models.QuorumDataType.issue_management.icon.replace("fa-", ""),

        firstLineData: datum.get("name"),
        secondLineData: getSecondLine(),
        thirdLineData: getThirdLine(),
        thirdLineIsHtml,

        secondLineDS: getSecondLineDS(),
    }
}

export const attachmentInlineSelector = (datum) => ({
    iconName: DjangIO.app.models.QuorumDataType.attachment.icon.replace(/(fa-?\s*)*/, ""),

    firstLineData: datum.get("name", ""),
    secondLineData: DjangIO.app.userdata.types.AttachmentType.by_value(datum.get("type", "")).label,
    thirdLineData: humanFileSize(datum.get("size", "")),
})

export const userInlineSelector = (datum) => {
    const getSecondLine = () => {
        const secondLine = []

        const username = datum.get("username", "")
        if (username) {
            secondLine.push(username)
        }

        const organization = datum.get("user_info__organization__name")
        if (organization) {
            secondLine.push(organization)
        }

        return secondLine.join(" | ")
    }
    return {
        iconName: DjangIO.app.models.QuorumDataType.user.icon.replace("fa-", ""),

        firstLineData: datum.get("name", ""),
        secondLineData: getSecondLine(),
        thirdLineData: datum.get("email", ""),
        isHalfWidth: true,
    }
}

export const eventParticipantInlineSelector = (datum) => {
    const getSecondLine = () => {
        const secondLine = []

        const qdt = datum.get("participant_type")
        if (qdt) {
            secondLine.push(DjangIO.app.models.QuorumDataType.by_value(qdt).singular)
        }

        const primaryContact = datum.get("primary_contact")
        if (primaryContact) {
            secondLine.push(primaryContact)
        }

        const secondaryContact = datum.get("secondary_contact")
        if (secondaryContact) {
            secondLine.push(secondaryContact)
        }

        return secondLine.join(" | ")
    }

    const getThirdLine = () => {
        const updated = datum.get("updated")
        if (updated) {
            return `Last Updated ${parseDateTime(updated)}`
        }

        return undefined
    }
    return {
        hasNoProfile: true,
        iconName: DjangIO.app.models.QuorumDataType.user.icon.replace("fa-", ""),

        firstLineData: datum.get("name"),
        secondLineData: getSecondLine(),
        thirdLineData: getThirdLine(),
    }
}

export const noteInlineSelector = (datum) => {
    // datum fields
    const bills = datum.getIn(["_extra", "bills"]) && datum.getIn(["_extra", "bills"]).toJS()
    const cacheJson = datum.get("cache_json")

    // cacheJson fields
    const attachment = cacheJson && cacheJson.get("attachments")
    let attachments
    if (attachment) {
        attachments = attachment.toJS()
    }

    const objectDescription = cacheJson && cacheJson.get("object_description")

    const name = cacheJson && (cacheJson.getIn(["user", "name"]) || cacheJson.getIn(["user", "username"]) || "Quorum")

    const commentTypeString = cacheJson && cacheJson.get("comment_type_string")

    let firstLineIsHtml = false
    let secondLineIsHtml = false
    let thirdLineIsHtml = false
    let dangerouslyInsertThirdLine = false

    const getFirstLine = (full = false) => {
        const text = datum.get("humanized_text")
        const fullText = datum.get("full_humanized_text")

        if (stringContainsHTMLMarkup(text)) {
            firstLineIsHtml = true
        }
        if (full && fullText && text !== fullText) {
            return fullText
        }

        return text
    }

    const getSecondLine = () => {
        const detailsArray = []

        const searchableDate = datum.get("searchable_date")
        if (searchableDate && datum.get("note_type") === DjangIO.app.userdata.types.NoteType.interaction.value) {
            detailsArray.push(datum.get("searchable_date"))
        }
        // if the cache json exists
        if (cacheJson) {
            // if name exists then add to the details string
            // TODO: this might need to be deleted, see DLC Megalist for more info.
            if (name) {
                detailsArray.push(`Posted by ${name}`)
            }

            const created = datum.get("searchable_created_date")
            if (created) {
                detailsArray.push("Created on " + created)
            }

            // create string list of IssueManagement issues m2m
            const issues = datum.getIn(["_extra", "issues"])
            if (issues) {
                const issuesString = issues.map((issue) => issue.get("name")).join(", ")

                if (issuesString) {
                    detailsArray.push(issuesString)
                }
            }
        }
        // return the string for details
        const text = detailsArray.join(" | ")

        if (stringContainsHTMLMarkup(text)) {
            secondLineIsHtml = true
        }

        return text
    }

    const getShrunkSecondLine = () => datum.get("searchable_date")

    const getThirdLine = () => {
        const interactionType = datum.get("interaction_type")
        const noteType = datum.get("note_type")

        let text = datum.get("text")
        if (text) {
            if (text.includes("\n")) {
                text = simpleLineBreak(text)
            }
            text = replaceAtMentionsWithTags(text)

            if (
                interactionType === DjangIO.app.userdata.types.InteractionType.email_exchange.value ||
                interactionType === DjangIO.app.userdata.types.InteractionType.email_received.value
            ) {
                // dangerously insert all html if it is an email
                // (our manual parser can't handle the weird stuff yet)
                dangerouslyInsertThirdLine = true
            } else if (stringContainsHTMLMarkup(text)) {
                // only parse through the string if it contains valid html
                thirdLineIsHtml = true
                if (noteType === DjangIO.app.userdata.types.NoteType.comment.value) {
                    return sanitizeUserCommentTextHtml(text)
                }
            }

            return text
        }

        return undefined
    }

    const getIconClassName = () => {
        if (datum.get("note_type") === DjangIO.app.userdata.types.NoteType.interaction.value) {
            if (datum.get("interaction_type")) {
                return DjangIO.app.userdata.types.InteractionType.by_value(datum.get("interaction_type")).icon
            }
            return cacheJson.get("icon")
        } else if (datum.get("note_type") === DjangIO.app.userdata.types.NoteType.relationship.value) {
            return DjangIO.app.userdata.types.RelationshipType.by_value(datum.get("relationship_type")).icon
        }
        return datum.get("icon") || DjangIO.app.models.QuorumDataType.note.icon
    }

    const getSecondLineDS = (full = false) => {
        const secondLine = []

        const searchableDate = datum.get("searchable_date")
        if (searchableDate && datum.get("note_type") === DjangIO.app.userdata.types.NoteType.interaction.value) {
            secondLine.push({
                ...getSecondLineIcon("date"),
                value: searchableDate,
            })
        }

        const cacheJson = datum.get("cache_json")
        if (cacheJson) {
            if (name) {
                secondLine.push({
                    ...getSecondLineIcon("post"),
                    value: `Posted by ${name}`,
                })
            }

            const created = datum.get("searchable_created_date")
            if (created) {
                secondLine.push({
                    ...getSecondLineIcon("calendar"),
                    value: "Created on " + created,
                })
            }

            const issues = getIssues(datum.getIn(["_extra", "issues"]))
            if (issues) {
                secondLine.push({
                    ...getSecondLineIcon("issues"),
                    value: issues,
                })
            }
        }

        const subject = datum.get("raw_content").match(/(Subject: .*)\n/)
        if (subject) {
            secondLine.push({
                ...getSecondLineIcon("mail"),
                value: subject[1],
            })
        }

        if (bills && bills.length) {
            const billsToRender = full ? bills : bills.slice(0, NOTE_BILL_TRUNCATION_LIMIT)
            billsToRender.forEach((bill) => {
                secondLine.push({
                    ...getSecondLineIcon("type"),
                    id: bill.id,
                    profile: DjangIO.app.models.QuorumDataType.bill.profile,
                    value: `${DjangIO.app.models.Region.by_value(bill.region).abbrev.toUpperCase()} ${bill.title}`,
                })
            })
        }

        return secondLine
    }

    return {
        attachments,
        bills,
        hasNoProfile: true,
        iconName: getIconClassName().replace("fa-", ""),
        rawContent:
            name && objectDescription
                ? `${name} commented on ${objectDescription} (${commentTypeString})`
                : datum.get("raw_content"),
        renderBills: bills && Boolean(bills.length),

        firstLineData: getFirstLine(),
        secondLineData: getSecondLine(),
        thirdLineData: getThirdLine(),
        firstLineIsHtml,
        secondLineIsHtml,
        thirdLineIsHtml,
        dangerouslyInsertThirdLine,
        // used by mobile GenericInlinePresentation
        shrinkSecondLineForMobile: getShrunkSecondLine(),

        secondLineDS: getSecondLineDS(),

        // used by expandable search card view
        firstLineFullData: getFirstLine(true),
        secondLineFullDS: getSecondLineDS(true),
    }
}

export const grassrootsActionInlineSelector = (datum, uniqueFlvId) => {
    const QuorumDataType = DjangIO.app.models.QuorumDataType
    const NoteType = DjangIO.app.userdata.types.NoteType
    const GrassrootsInteractionType = DjangIO.app.userdata.types.GrassrootsInteractionType
    const RelationshipType = DjangIO.app.userdata.types.RelationshipType

    // datum fields
    const cacheJson = datum.get("cache_json")
    const objectDescription = cacheJson.get("object_description")
    const name = cacheJson.getIn(["user", "name"]) || cacheJson.getIn(["user", "username"]) || "Quorum"
    const commentTypeString = cacheJson.get("comment_type_string")
    const isMultiAction = datum.getIn(["_extra", "campaign", "is_multi_action"])

    let firstLineIsHtml = false
    let secondLineIsHtml = false
    let thirdLineIsHtml = false

    const date = datum.get("date")

    const getIconClassName = () => {
        if (datum.get("note_type") === NoteType.interaction.value && datum.get("interaction_type") != null) {
            return GrassrootsInteractionType.by_value(datum.get("interaction_type")).icon
        } else if (datum.get("note_type") === NoteType.relationship.value && datum.get("relationship_type") != null) {
            return RelationshipType.by_value(datum.get("relationship_type")).icon
        } else if (datum.get("icon") != null) {
            return datum.get("icon")
        }
        return QuorumDataType.note.icon
    }

    const getInteractionType = () => {
        let interactionType
        if (datum.get("note_type") === NoteType.interaction.value && datum.get("interaction_type") != null) {
            interactionType = GrassrootsInteractionType.by_value(datum.get("interaction_type")).label
        } else if (datum.get("note_type") === NoteType.relationship.value && datum.get("relationship_type") != null) {
            interactionType = RelationshipType.by_value(datum.get("relationship_type")).label
        }
        if (!interactionType) return undefined
        return isMultiAction ? `Multi Action ${interactionType}` : interactionType
    }

    const getFirstLine = () => {
        const text = datum.get("humanized_text")

        if (stringContainsHTMLMarkup(text)) {
            firstLineIsHtml = true
        }

        return text
    }

    const getSecondLine = () => {
        const secondLine = []

        const interactionType = getInteractionType()
        if (interactionType) {
            secondLine.push(interactionType)
        }

        // if the cache json exists
        if (cacheJson) {
            const campaign = datum.getIn(["cache_json", "campaign"])
            if (campaign) {
                secondLineIsHtml = true
                secondLine.push(`<a href="/grassroots_campaign/${campaign.get("id")}/">${campaign.get("name")}</a>`)
            }

            // if name exists then add to the details string
            // TODO: this might need to be deleted, see DLC Megalist for more info.
            if (name) {
                secondLine.push(`Posted by ${name}`)
            }

            if (date) {
                secondLine.push(parseDateTime(date))
            }

            // create string list of project (if it exists)
            const projects = datum.getIn(["cache_json", "projects"])
            if (projects) {
                secondLineIsHtml = true
                projects.map((proj) =>
                    secondLine.push(`<a href="/projects/${proj.get("id")}/">${proj.get("name")}</a>`),
                )
            }
        }

        // return the string for details
        return secondLine.join(" | ")
    }

    /**
     * A helper method for the GrassRootsActionInlineSelector that returns what to display for the
     * 3rd line for the GrassRoots inline which potentiallly consists of a subject and text.
     *
     * Possible outcomes:
     *      (1) The subject + text with linebreaks in betweeen, always HTML so there can be <br /> tags for the linebreak
     *      (2) Just the subject, renders HTML if the subject data contains HTML markup
     *      (3) Just text, renders HTML if the text data contains HTML markup or there is /n for a linebreak (will replace with  <br />)
     *      (4) An empty string if neither a subject nor text exists
     *
     * @returns string - a string representation of what to display for the 3rd line.
     */
    const getThirdLine = () => {
        const subject = datum.get("subject")
        const text = datum.get("text")
        thirdLineIsHtml = stringContainsHTMLMarkup(subject) || stringContainsHTMLMarkup(text)

        if (subject && text) {
            thirdLineIsHtml = true // s/b true bc there needs to be line breaks (<br />) bn the subject and text
            return `${subject}<br /><br />${text}`
        }

        if (subject) {
            return subject
        }

        if (text) {
            if (text.includes("\n")) {
                thirdLineIsHtml = true // simpleLineBreak replaces /n with <br /> so thirdLineIsHtml s/b true
                return simpleLineBreak(text)
            }
            return text
        }

        return ""
    }

    let secondLineDSIsHtml = false
    const getSecondLineDS = () => {
        const secondLine = []

        if (date) {
            secondLine.push({
                ...getSecondLineIcon("date"),
                value: parseDateTime(date),
            })
        }

        const interactionType = getInteractionType()
        if (interactionType) {
            secondLine.push({
                ...getSecondLineIcon("bullhorn"),
                value: interactionType,
            })
        }

        if (cacheJson) {
            if (name) {
                secondLine.push({
                    ...getSecondLineIcon("post"),
                    value: `Posted by ${name}`,
                })
            }

            const campaign = datum.getIn(["cache_json", "campaign"])
            if (campaign) {
                secondLineDSIsHtml = true
                secondLine.push({
                    value: `<a href="/grassroots_campaign/${campaign.get("id")}/">${campaign.get("name")}</a>`,
                })
            }
        }

        const charityName = datum.getIn(["_extra", "charity_name"])
        if (charityName && isFeatureEnabled("ff_pac_charitable_match_search") && Userdata.Permissions.qp_pac_match) {
            secondLine.push({
                ...getSecondLineIcon("usd-square"),
                value: `Charity Name: ${charityName}`,
            })
        }

        return secondLine
    }

    const isVideo = Boolean(datum.get("share_story_video_uuid"))

    const rightSection = isVideo
        ? {
              component: GrassrootsInlineRightSection,
              props: {
                  uniqueFlvId: uniqueFlvId,
                  confirmed: datum.get("confirmed"),
                  actionId: datum.get("id"),
                  videoUrl: datum.getIn(["_extra", "story_video", "video_url"]),
                  videoThumbnail: datum.getIn(["_extra", "story_video", "video_thumbnail"]),
              },
          }
        : null

    return {
        hasNoProfile: true,
        iconName: getIconClassName().replace("fa-", ""),
        rightSection,
        rawContent:
            name && objectDescription
                ? `${name} commented on ${objectDescription} (${commentTypeString})`
                : datum.get("raw_content"),
        // used by mobile GenericInlinePresentation
        shrinkSecondLineForMobile: date ? parseDateTime(date) : undefined,

        firstLineData: getFirstLine(),
        secondLineData: getSecondLine(),
        thirdLineData: getThirdLine(),
        firstLineIsHtml,
        secondLineIsHtml,
        thirdLineIsHtml,

        secondLineDS: getSecondLineDS(),
        secondLineDSIsHtml,
    }
}

export const getEventChamber = (region, chamber) => {
    switch (chamber) {
        case DjangIO.app.models.CongressChamber.house.value:
            return DjangIO.app.models.Region.by_value(region).lower_chamber_title
        case DjangIO.app.models.CongressChamber.senate.value:
            return DjangIO.app.models.Region.by_value(region).upper_chamber_title
        case DjangIO.app.models.CongressChamber.both.value:
            return "Joint"
        case DjangIO.app.models.CongressChamber.executive.value:
            return DjangIO.app.models.CongressChamber.executive.label
        default:
            return ""
    }
}

export const eventInlineSelector = (datum) => {
    let firstLineIsHtml = false
    let secondLineIsHtml = false
    let thirdLineIsHtml = false
    let dangerouslyInsertThirdLine = false

    const parseDateTimeTimezone = (dateTime, region = null, timeOnly = false, dateOnly = false) => {
        const dateTimeMoment = moment(dateTime)
        // so since scraped data is not in UTC just tack on the region's timezone

        let timeString = ""
        if (dateTimeMoment.isValid()) {
            if (timeOnly) {
                // if we specify we need only the time, no day
                timeString = dateTimeMoment.format("h:mm a")
                return toDateTimeString(dateTime, region, false, false, true, Userdata.has_international_region)
            } else if (dateOnly) {
                return toDateTimeString(dateTime, region, true, false, false, Userdata.has_international_region)
            } else if (dateTimeMoment.hour() !== 0) {
                // if the hour isn't midnight
                return toDateTimeString(dateTime, region, false, false, false, Userdata.has_international_region)
            }
            // if it's midnight, don't show the time: it's probably an all-day event
            return toDateTimeString(dateTime, region, true, false, false, Userdata.has_international_region)
        }

        return (
            // if there's a region, we want a timezone!
            region ? `${timeString} ${DjangIO.app.models.Region.by_value(region).timezone}` : timeString
        )
    }

    const getRegion = () => {
        const regionChamber = []
        const region = datum.get("region") && DjangIO.app.models.Region.by_value(datum.get("region")).region_name
        if (region) {
            regionChamber.push(region)
        }

        const chamber = getEventChamber(datum.get("region"), datum.get("chamber"))
        if (chamber) {
            regionChamber.push(`(${chamber})`)
        }

        return regionChamber.join(" ")
    }

    const getFirstLine = () => {
        const firstLine = []
        const title =
            datum.get("subject") || DjangIO.app.events.models.EventType.by_value(datum.get("event_type")).label
        const sourceCommitteeName = datum.get("source_committee_name")

        // some event Inlines seem to have external URLs in their first line
        if (
            (title && stringContainsHTMLMarkup(title)) ||
            (sourceCommitteeName && stringContainsHTMLMarkup(sourceCommitteeName))
        ) {
            firstLineIsHtml = true
        }

        if (sourceCommitteeName) {
            firstLine.push(sourceCommitteeName)
        }

        if (title) {
            firstLine.push(title)
        }

        return firstLine.join(" ")
    }

    const getSecondLine = () => {
        const definedFields = []
        const committee = datum.getIn(["_extra", "committee"])
        const committeeId = committee && committee.get("source_committee_id")
        const committeeName = committee && committee.get("source_committee__name")

        if (
            datum.get("event_type") === DjangIO.app.events.models.EventType.us_president_address.value ||
            datum.get("event_type") === DjangIO.app.events.models.EventType.committee_meeting.value ||
            datum.get("event_type") === DjangIO.app.events.models.EventType.state_floor_event.value ||
            datum.get("event_type") === DjangIO.app.events.models.EventType.council_meeting.value
        ) {
            // if transcript has been generated...
            if (
                datum.get("meeting_video_pipeline_status") ===
                DjangIO.app.events.types.MeetingVideoPipelineStatus.parsing_transcription_ended.value
            ) {
                // if a verified transcript has been generated and the feature flag is enabled
                if (
                    datum.getIn(["_extra", "manualtranscriptinfo"]) &&
                    datum
                        .getIn(["_extra", "manualtranscriptinfo"])
                        .get("manualtranscriptinfo__meeting_video_pipeline_status") ===
                        DjangIO.app.events.types.MeetingVideoPipelineStatus.parsing_transcription_ended.value
                ) {
                    definedFields.push(
                        `<a href="${DjangIO.app.models.QuorumDataType.event.profile}${datum.get(
                            "id",
                        )}/transcript/">Verified Transcript Available</a>`,
                    )
                } else {
                    definedFields.push(
                        `<a href="${DjangIO.app.models.QuorumDataType.event.profile}${datum.get(
                            "id",
                        )}/transcript/">Transcript Available</a>`,
                    )
                }
            }

            if (committeeId && committeeName) {
                definedFields.push(
                    `<a href="${DjangIO.app.models.QuorumDataType.committee.profile}${committeeId}/">${committeeName}</a>`,
                )
            }
        }

        if (datum.get("when") && datum.get("end")) {
            let startDateTime
            let endDateTime

            if (moment(datum.get("when")).isSame(moment(datum.get("end")), "day")) {
                // if they're the same day, no point in providing the day twice, hence timeOnly = true
                // additionally, no point in providing the timezone twice ever.
                startDateTime = parseDateTimeTimezone(datum.get("when"))
                endDateTime = parseDateTimeTimezone(datum.get("end"), datum.get("region"), true)
            } else {
                startDateTime = parseDateTimeTimezone(datum.get("when"))
                endDateTime = parseDateTimeTimezone(datum.get("end"), datum.get("region"))
            }

            definedFields.push(`${startDateTime} to ${endDateTime}`)
        } else if (datum.get("when")) {
            definedFields.push(parseDateTimeTimezone(datum.get("when"), datum.get("region")))
        } else {
            definedFields.push("Date TBD")
        }

        const additionalFields = ["sponsoring_organization_name", "location", "address", "channel_string"]
        const additionalFieldsArray = getDefinedFields(datum, additionalFields)

        if (datum.get("point_person_name")) {
            definedFields.push(`Point Person: ${datum.get("point_person_name")}`)
        }

        if (additionalFieldsArray.length) {
            definedFields.push(additionalFieldsArray.join(" | "))
        }

        return definedFields.join(" | ")
    }

    const getThirdLine = () => {
        const thirdLine = datum.get("description")
        if (thirdLine) {
            if (stringContainsHTMLMarkup(thirdLine)) {
                dangerouslyInsertThirdLine = true
            } else if (thirdLine.includes("\n")) {
                thirdLineIsHtml = true
                return simpleLineBreaks(thirdLine)
            }
        }

        return thirdLine
    }

    const id = datum.get("id")
    const profile = datum.get("cspan_id") ? "event_cspan" : "event"

    const secondLine = getSecondLine()
    if (stringContainsHTMLMarkup(secondLine)) {
        secondLineIsHtml = true
    }

    let secondLineDSIsHtml = false
    const getSecondLineDS = () => {
        const secondLine = []

        const committee = datum.getIn(["_extra", "committee"])
        const committeeId = committee && committee.get("source_committee_id")
        const committeeName = committee && committee.get("source_committee__name")
        if (
            datum.get("event_type") === DjangIO.app.events.models.EventType.us_president_address.value ||
            datum.get("event_type") === DjangIO.app.events.models.EventType.committee_meeting.value ||
            datum.get("event_type") === DjangIO.app.events.models.EventType.state_floor_event.value ||
            datum.get("event_type") === DjangIO.app.events.models.EventType.council_meeting.value
        ) {
            // if transcript has been generated.
            if (
                datum.get("meeting_video_pipeline_status") ===
                DjangIO.app.events.types.MeetingVideoPipelineStatus.parsing_transcription_ended.value
            ) {
                secondLineDSIsHtml = true
                // if a verified transcript has been generated and feature flag is enabled
                if (
                    datum.getIn(["_extra", "manualtranscriptinfo"]) &&
                    datum
                        .getIn(["_extra", "manualtranscriptinfo"])
                        .get("manualtranscriptinfo__meeting_video_pipeline_status") ===
                        DjangIO.app.events.types.MeetingVideoPipelineStatus.parsing_transcription_ended.value
                ) {
                    secondLine.push({
                        value: `<a href="${DjangIO.app.models.QuorumDataType.event.profile}${datum.get(
                            "id",
                        )}/transcript/">Verified Transcript Available</a>`,
                    })
                } else {
                    secondLine.push({
                        value: `<a href="${DjangIO.app.models.QuorumDataType.event.profile}${datum.get(
                            "id",
                        )}/transcript/">Transcript Available</a>`,
                    })
                }
            }

            if (committeeId && committeeName) {
                secondLineDSIsHtml = true
                secondLine.push({
                    ...getSecondLineIcon("committee"),
                    value: `<a href="${DjangIO.app.models.QuorumDataType.committee.profile}${committeeId}/">${committeeName}</a>`,
                })
            }
        }
        if (datum.get("is_custom_all_day_event")) {
            secondLine.push({
                ...getSecondLineIcon("date"),
                // if it's a custom all day event, then only show the date (not the time)
                value: parseDateTimeTimezone(datum.get("when"), datum.get("region"), false, true),
            })
        } else if (datum.get("when") && datum.get("end")) {
            let startDateTime
            let endDateTime

            if (moment(datum.get("when")).isSame(moment(datum.get("end")), "day")) {
                // if they're the same day, no point in providing the day twice, hence timeOnly = true
                // additionally, no point in providing the timezone twice ever.
                startDateTime = parseDateTimeTimezone(datum.get("when"))
                endDateTime = parseDateTimeTimezone(datum.get("end"), datum.get("region"), true)
            } else {
                startDateTime = parseDateTimeTimezone(datum.get("when"))
                endDateTime = parseDateTimeTimezone(datum.get("end"), datum.get("region"))
            }

            secondLine.push({
                ...getSecondLineIcon("date"),
                value: `${startDateTime} to ${endDateTime}`,
            })
        } else if (datum.get("when")) {
            secondLine.push({
                ...getSecondLineIcon("date"),
                value: parseDateTimeTimezone(datum.get("when"), datum.get("region")),
            })
        } else {
            secondLine.push({
                ...getSecondLineIcon("date"),
                value: "Date TBD",
            })
        }

        if (datum.get("point_person_name")) {
            secondLine.push({
                value: `Point Person: ${datum.get("point_person_name")}`,
            })
        }

        if (datum.get("location")) {
            secondLine.push({
                ...getSecondLineIcon("map-marker-alt"),
                value: datum.get("location"),
            })
        }

        const additionalFields = ["sponsoring_organization_name", "address", "channel_string"]
        const additionalFieldsArray = getDefinedFieldsDS(datum, additionalFields) || []

        return [...secondLine, ...additionalFieldsArray]
    }

    return {
        href: `/${profile}/${id}/`,
        iconName: DjangIO.app.events.models.EventType.by_value(datum.get("event_type")).icon.replace(/fa-|fa|\s/g, ""),
        model: DjangIO.app.models.QuorumDataType.event.value,
        region: getRegion(),
        showRegion:
            Userdata.isStatesMode() && datum.get("event_type") !== DjangIO.app.events.models.EventType.custom.value,

        firstLineData: getFirstLine(),
        secondLineData: getSecondLine(),
        thirdLineData: getThirdLine(),
        firstLineIsHtml,
        secondLineIsHtml,
        thirdLineIsHtml,
        dangerouslyInsertThirdLine,

        secondLineDS: getSecondLineDS(),
        secondLineDSIsHtml,
    }
}

interface VoteCounts {
    ayes: number | undefined
    nays: number | undefined
    abstentions: number | undefined
    other: number | undefined
}

export const voteInlineSelector = (datum) => {
    const chamberVal = datum.get("chamber")
    const chamber = chamberVal && DjangIO.app.models.CongressChamber.by_value(chamberVal).label.replace("Both", "Joint")
    const question = datum.get("question")

    let renderBills = false
    const getRegion = () => {
        const regionArr = []

        const regionVal = datum.get("region")
        const region = regionVal && DjangIO.app.models.Region.by_value(regionVal).region_name
        const rollCallNumber = datum.get("number")

        if (region) {
            regionArr.push(region)
        }
        if (chamber) {
            regionArr.push(chamber)
        }
        if (rollCallNumber) {
            regionArr.push(`${chamber[0]}${rollCallNumber}`)
        }

        return regionArr.join(" ")
    }

    const getVoteCounts = (): VoteCounts => ({
        ayes: datum.get("total_plus"),
        nays: datum.get("total_minus"),
        abstentions: datum.get("total_abstain"),
        other: datum.get("total_other"),
    })

    const getFirstLine = () => {
        if (question) {
            return question.split(":").reverse().join(":").replace(":", " on ")
        }

        return undefined
    }

    const firstLineData = getFirstLine()

    const getSecondLine = () => {
        const secondLine = []
        const category = datum.get("category")
        const categoryType = category && DjangIO.app.vote.models.VoteCategory.by_value(category).label
        const result = datum.get("result")
        const created = datum.get("created")

        if (!Userdata.isStatesMode() && categoryType) {
            secondLine.push(categoryType)
        }

        if (result && !question.includes(result)) {
            secondLine.push(result)
        }

        if (created) {
            secondLine.push(`${parseDate(created)}`)
        }

        return secondLine.join(" | ")
    }

    const getThirdLine = () => {
        const voteCounts = getVoteCounts()
        const status = []

        if (!voteCounts.ayes && !voteCounts.nays) {
            status.push("Voice Vote")
            return status.join(" | ")
        }

        if (voteCounts.ayes >= 0) {
            status.push(`${voteCounts.ayes} Ayes`)
        }

        if (voteCounts.nays >= 0) {
            status.push(`${voteCounts.nays} Nays`)
        }

        if (voteCounts.abstentions >= 0) {
            status.push(`${voteCounts.abstentions} Abstentions`)
        }

        if (voteCounts.other >= 0) {
            status.push(`${voteCounts.other} Absences`)
        }

        return status.join(" | ")
    }

    const relatedBill = datum.get("related_bill")
    if (relatedBill) {
        renderBills = true
    }

    const getSecondLineDS = () => {
        const secondLine = []
        const category = datum.get("category")
        const categoryType = category && DjangIO.app.vote.models.VoteCategory.by_value(category).label
        const result = datum.get("result")
        const created = datum.get("created")
        const voteCounts = getVoteCounts()

        if (created) {
            secondLine.push({
                ...getSecondLineIcon("date"),
                value: `${parseDate(created)}`,
            })
        }

        if (!Userdata.isStatesMode() && categoryType) {
            secondLine.push({
                ...getSecondLineIcon("ballot"),
                value: categoryType,
            })
        }

        if (result && !question.includes(result)) {
            secondLine.push({
                ...getSecondLineIcon("ballot"),
                value: result,
            })
        }

        const voteResults = []
        if (!voteCounts.ayes && !voteCounts.nays) {
            voteResults.push("Voice Vote")
        } else {
            if (voteCounts.ayes >= 0) {
                voteResults.push(`${voteCounts.ayes} Ayes`)
            }

            if (voteCounts.nays >= 0) {
                voteResults.push(`${voteCounts.nays} Nays`)
            }

            if (voteCounts.abstentions >= 0) {
                voteResults.push(`${voteCounts.abstentions} Abstentions`)
            }

            if (voteCounts.other >= 0) {
                voteResults.push(`${voteCounts.other} Absences`)
            }
        }
        if (voteResults.length) {
            secondLine.push({
                ...getSecondLineIcon("box-ballot"),
                value: voteResults.join(" | "),
            })
        }

        const relatedBillObj = relatedBill && relatedBill.toJS()
        if (relatedBillObj) {
            secondLine.push({
                ...getSecondLineIcon("type"),
                id: relatedBillObj.id,
                profile: DjangIO.app.models.QuorumDataType.bill.profile,
                value:
                    `${DjangIO.app.models.Region.by_value(relatedBillObj.region).abbrev.toUpperCase()}` +
                    ` ${relatedBillObj.label}: ${relatedBillObj.title}`,
            })
        }

        return secondLine
    }

    return {
        bill: relatedBill && relatedBill.toJS(),
        disableRegionColon: true,
        href: `/vote/${datum.get("id")}/`,
        iconName: DjangIO.app.vote.models.VoteResultType.by_value(datum.get("result_type")).icon.replace("fa-", ""),
        model: DjangIO.app.models.QuorumDataType.vote.value,
        region: getRegion(),
        renderBills,
        showRegion: true,
        voteResult: DjangIO.app.vote.models.VoteResultType.by_value(datum.get("result_type")).slug,

        firstLineData,
        secondLineData: getSecondLine(),
        thirdLineData: getThirdLine(),
        thirdLineIsHtml: true,
        squashThirdLine: true,

        secondLineDS: getSecondLineDS(),
    }
}

export const amendmentInlineSelector = (datum) => {
    let thirdLineIsHtml = false

    const getSecondLine = () => {
        const secondLine = []

        if (datum.get("status_date")) {
            secondLine.push(
                `${DjangIO.app.bill.models.AmendmentStatus.by_value(datum.get("status")).full_name} on ` +
                    `${parseDate(datum.get("status_date"))}`,
            )
        } else {
            secondLine.push(DjangIO.app.bill.models.AmendmentStatus.by_value(datum.get("status")).full_name)
        }

        return secondLine.join(" | ")
    }

    const getThirdLine = () => {
        if (datum.get("sponsor_name")) {
            thirdLineIsHtml = true

            return `Sponsored by <a href="${DjangIO.app.models.QuorumDataType.person.profile}${datum.get(
                "sponsor_id",
            )}/">${datum.get("sponsor_name")}</a>`
        }

        return undefined
    }

    const getFourthLine = () => {
        if (datum.get("bill_name")) {
            return `Bill: <a href="${DjangIO.app.models.QuorumDataType.bill.profile}${datum.get(
                "bill_id",
            )}/">${datum.get("bill_name")}</a>`
        }

        return undefined
    }

    let secondLineDSIsHtml = false
    const getSecondLineDS = () => {
        const secondLine = []

        if (datum.get("status_date")) {
            secondLine.push({
                ...getSecondLineIcon("date"),
                value:
                    `${DjangIO.app.bill.models.AmendmentStatus.by_value(datum.get("status")).full_name} on ` +
                    `${parseDate(datum.get("status_date"))}`,
            })
        } else {
            secondLine.push({
                ...getSecondLineIcon("status"),
                value: DjangIO.app.bill.models.AmendmentStatus.by_value(datum.get("status")).full_name,
            })
        }

        if (datum.get("sponsor_name")) {
            secondLineDSIsHtml = true

            const href = `${DjangIO.app.models.QuorumDataType.person.profile}${datum.get("sponsor_id")}/`
            secondLine.push({
                ...getSecondLineIcon("pen"),
                value: `Sponsored by <a href="${href}">${datum.get("sponsor_name")}</a>`,
            })
        }

        if (datum.get("bill_name")) {
            secondLine.push({
                ...getSecondLineIcon("type"),
                id: datum.get("bill_id"),
                profile: DjangIO.app.models.QuorumDataType.bill.profile,
                value: datum.get("bill_name"),
            })
        }

        return secondLine
    }

    return {
        hasBeenEnacted: datum.get("status") === DjangIO.app.bill.models.AmendmentStatus.passed.value,
        href: `/amendment/${datum.get("id")}/`,
        iconName: DjangIO.app.models.QuorumDataType.amendment.icon.replace("fa-", ""),
        model: DjangIO.app.models.QuorumDataType.amendment.value,

        firstLineData: datum.get("title"),
        secondLineData: getSecondLine(),
        thirdLineData: getThirdLine(),
        fourthLineData: getFourthLine(),
        thirdLineIsHtml,
        fourthLineIsHtml: true,
        squashThirdLine: true,

        secondLineDS: getSecondLineDS(),
        secondLineDSIsHtml,
    }
}

export const committeeInlineSelector = (datum) => {
    const getFirstLine = () => {
        const firstLine = []

        const fullCommittee = datum.get("parent_committee_name")
        if (fullCommittee) {
            firstLine.push(fullCommittee)
        }

        const name = datum.get("name")
        if (name) {
            firstLine.push(name)
        }

        return firstLine.join(": ")
    }

    const getSecondLine = () => {
        // Change the words if we are in EU
        const chairsText = Userdata.isOnlyEUMode() ? "Chair:" : "Chair Persons:"
        const rankingText = Userdata.isOnlyEUMode() ? "Vice-Chairs:" : "Ranking Members:"
        const fullStringText = `${chairsText} ${datum.get("chairs_string")} | ${rankingText} ${datum.get(
            "ranking_members_string",
        )}`
        return datum.get("chairs_string") !== "N/A" || datum.get("ranking_members_string") !== "N/A"
            ? fullStringText
            : undefined
    }

    const getThirdLine = () => {
        if (getIsMobile()) {
            return ""
        }

        const thirdLine = []

        let address = datum.get("address")
        const phone = datum.get("phone")

        if (phone) {
            thirdLine.push(`Phone: ${phone}`)
        }
        if (address) {
            // strip newlines from address
            address = address.replace(/(\r\n\t|\n|\r\t)/gm, " ").trim()
            thirdLine.push(`<a href="http://maps.google.com/?q=${address}">${address}</a>`)
        }

        return thirdLine.join(" | ")
    }

    const getSecondLineDS = () => {
        const secondLine = []

        // Change the words if we are in EU
        const chairsText = Userdata.isOnlyEUMode() ? "Chair:" : "Chair Persons:"
        const rankingText = Userdata.isOnlyEUMode() ? "Vice-Chairs:" : "Ranking Members:"
        if (datum.get("chairs_string") !== "N/A" || datum.get("ranking_members_string") !== "N/A") {
            const fullString = []

            if (datum.get("chairs_string") !== "N/A") {
                fullString.push(`${chairsText} ${datum.get("chairs_string")}`)
            }

            if (datum.get("ranking_members_string") !== "N/A") {
                fullString.push(`${rankingText} ${datum.get("ranking_members_string")}`)
            }

            secondLine.push({
                ...getSecondLineIcon("podium"),
                value: fullString.join(" | "),
            })
        }

        const phone = datum.get("phone")
        if (phone) {
            secondLine.push({
                ...getSecondLineIcon("phone"),
                value: `Phone: ${phone}`,
            })
        }

        const address =
            datum.get("address") &&
            // strip newlines from address
            datum
                .get("address")
                .replace(/(\r\n\t|\n|\r\t)/gm, " ")
                .trim()
        if (address) {
            secondLine.push({
                ...getSecondLineIcon("map-marker-alt"),
                value: address,
            })
        }

        return secondLine
    }

    return {
        href: `/committee/${datum.get("id")}/`,
        iconName: (datum.get("parent_committee_name") ? "fa-user-plus" : datum.get("icon") || "fa-user-plus").replace(
            "fa-",
            "",
        ),
        region: datum.get("region") && DjangIO.app.models.Region.by_value(datum.get("region")).region_name,
        showRegion: !(Userdata.isOnlyFederalMode() || Userdata.isOnlyEUMode()),

        firstLineData: getFirstLine(),
        secondLineData: getSecondLine(),
        thirdLineData: getThirdLine(),
        thirdLineIsHtml: true,

        secondLineDS: getSecondLineDS(),
    }
}

export const caucusInlineSelector = (datum) => {
    const getSecondLine = () => {
        let secondLine = ""
        const chairPerson = datum.get("chairs_string")

        if (chairPerson !== "N/A") {
            secondLine += `Chair Persons: ${chairPerson}`
        }

        return secondLine
    }

    const iconName = datum.get("icon").replace("fa-", "")

    return {
        ...committeeInlineSelector(datum),
        href: `/caucus/${datum.get("id")}/`,
        iconName,

        secondLineData: getSecondLine(),
    }
}

export const newListInlineSelector = (datum) => {
    const projectId = DjangIO.app.userdata.models.Project.idFromResourceUri(datum.get("project"))
    const dataTypeLabel = DjangIO.app.models.QuorumDataType.by_value(datum.get("data_type")).plural
    return {
        href: `/issue_management/${projectId}/`,
        iconName: DjangIO.app.models.QuorumDataType.new_list.icon.replace("fa-", ""),

        firstLineData: `${dataTypeLabel} | ${datum.get("name")}`,
    }
}

export const legislativeSessionInlineSelector = (datum) => ({
    iconName: "calendar-check-o",

    firstLineData: datum.get("title", ""),
    secondLineData: datum.get("end_date", "")
        ? `${parseDate(datum.get("start_date"))} to ${parseDate(datum.get("end_date"))}`
        : "",
})

export const fileInlineSelector = (datum) => {
    let thirdLineIsHtml = false
    const s3Url = datum.get("temp_s3_url") || datum.get("raw_s3_url")
    const mimeType = datum.get("mime_type")

    const getSecondLine = () => {
        const secondLine = []
        secondLine.push(
            `Uploaded by <a href="mailto:${datum.getIn(["user", "email"])}">${datum.getIn(["user", "name"])}</a> ` +
                `on ${getCreatedDate(datum.get("created"))} ${datum.get("project_name") && " to "}` +
                `<a href="/project_profile/${datum.get("project_id")}/">${datum.get("project_name")}</a>`,
        )
        if (datum.get("size")) {
            secondLine.push(`Size: ${humanFileSize(datum.get("size"))}`)
        }

        return secondLine.join(" | ")
    }

    const getThirdLine = () => {
        const thirdLine = []

        const description = datum.get("short_description")
        if (description) {
            thirdLine.push(description)
        }

        if (
            s3Url &&
            (mimeType === "image/png" ||
                mimeType === "image/jpeg" ||
                mimeType === "image/gif" ||
                mimeType === "image/bmp" ||
                mimeType === "image/webp")
        ) {
            thirdLine.push(`<a href="${s3Url}" target="_blank"><img src="${s3Url}"></a>`)
            thirdLineIsHtml = true
        }

        return simpleLineBreak(thirdLine.join("\n"))
    }

    const getPdf = () => (datum.get("mime_type") === "application/pdf" ? s3Url : undefined)

    const getSecondLineDS = () => {
        const secondLine = [
            {
                ...getSecondLineIcon("date"),
                value: parseDateTime(datum.get("created")),
            },
            {
                ...getSecondLineIcon("upload"),
                value: `Uploaded by <a href="mailto:${datum.getIn(["user", "email"])}">${datum.getIn([
                    "user",
                    "name",
                ])}</a>`,
            },
            ...(datum.get("is_public")
                ? [
                      {
                          ...getSecondLineIcon("globe"),
                          value: `Externally Shared File`,
                      },
                  ]
                : []),
        ]

        if (datum.get("project_name")) {
            secondLine.push({
                ...getSecondLineIcon("folder"),
                value: `<a href="/project_profile/${datum.get("project_id")}/">${datum.get(
                    "project_name",
                )}</a>, ${humanFileSize(datum.get("size"))}`,
            })
        }

        const description = datum.get("short_description")
        if (description) {
            secondLine.push({
                ...getSecondLineIcon("pencil-alt"),
                value: description,
            })
        }

        return secondLine
    }

    return {
        hasNoProfile: true,
        iconName: DjangIO.app.models.QuorumDataType.attachment.icon.replace("fa-", ""),
        pdfUrl: getPdf(),

        firstLineData: datum.get("name"),
        secondLineData: getSecondLine(),
        thirdLineData: getThirdLine(),
        secondLineIsHtml: true,
        thirdLineIsHtml,

        secondLineDS: getSecondLineDS(),
        secondLineDSIsHtml: true,
    }
}

export const bulkEmailInlineSelector = (datum) => {
    let thirdLineIsHtml = false
    let dangerouslyInsertThirdLine = false

    const getFirstLine = () => {
        const abTestDescription = datum.getIn(["_extra", "abtest_description"])
        return abTestDescription || datum.get("subject")
    }

    const getSecondLine = () => {
        const secondLine = []

        const creator = datum.getIn(["_extra", "creator"])

        if (creator) {
            secondLine.push(creator)
        }

        const time = datum.get("scheduled_send_time")
        if (time) {
            secondLine.push(parseDateTime(time))
        }

        const opens = datum.get("opens")
        if (opens) {
            secondLine.push(`${opens} Open${opens > 1 ? "s" : ""}`)
        }

        const clicks = datum.get("clicks")
        if (clicks) {
            secondLine.push(`${clicks} Click${clicks > 1 ? "s" : ""}`)
        }

        return secondLine.join(" | ")
    }

    const getThirdLine = () => {
        const text = datum.get("text")

        if (text) {
            if (stringContainsHTMLMarkup(text)) {
                thirdLineIsHtml = true
                dangerouslyInsertThirdLine = true
            }
            return text
        }

        return undefined
    }

    return {
        href: `/outbox/details/${datum.get("id")}/`,
        iconName: DjangIO.app.models.QuorumDataType.bulk_email.icon.replace("fa-", ""),

        firstLineData: getFirstLine(),
        secondLineData: getSecondLine(),
        thirdLineData: getThirdLine(),
        thirdLineIsHtml,
        dangerouslyInsertThirdLine,
    }
}

export const sendEmailInlineSelector = (datum) => {
    let firstLineIsHtml = false
    let thirdLineIsHtml = false
    let dangerouslyInsertThirdLine = false

    const bulkEmail = datum.getIn(["_extra", "bulk_email"])
    const sent = datum.get("sent")
    const email = datum.get("email_address")
    const sparkpostObj = datum.get("sparkpost_status")
    const sparkpostStatus = sparkpostObj && (sparkpostObj.includes("delivery") ? "delivered" : sparkpostObj)

    const getFirstLine = () => {
        const firstLine = []

        const name = datum.get("recipient_name") || email
        const personId = datum.get("person_id")
        const stafferId = datum.get("staffer_id")
        const supporterId = datum.get("supporter_id")
        const supporterOrg = datum.get("contact_organization")
        const personOrg = datum.get("person__primary_organization__name")

        if (name) {
            if (personId) {
                firstLineIsHtml = true
                firstLine.push(`<a href="${DjangIO.app.models.QuorumDataType.person.profile}${personId}"/>${name}</a>`)
            } else if (supporterId) {
                firstLineIsHtml = true
                firstLine.push(
                    `<a href="${DjangIO.app.models.QuorumDataType.supporter.profile}${supporterId}"/>${name}</a>`,
                )
            } else if (stafferId) {
                firstLineIsHtml = true
                firstLine.push(`<a href="${DjangIO.app.models.QuorumDataType.person.profile}${stafferId}"/>${name}</a>`)
            } else {
                firstLine.push(name)
            }

            const titleAndOrg = []
            const title = datum.get("title")
            if (title) {
                titleAndOrg.push(title)
            }

            if (supporterOrg) {
                titleAndOrg.push(supporterOrg)
            }

            if (personOrg) {
                titleAndOrg.push(personOrg)
            }

            if (titleAndOrg.length) {
                firstLine.push(`(${titleAndOrg.join(", ")})`)
            }

            const delivered = sparkpostStatus === "delivered"
            const opened = datum.get("opens")
            const clicked = datum.get("clicks")
            const replied = datum.get("did_reply")

            if (replied) {
                firstLine.push("replied to")
            } else if (clicked) {
                firstLine.push("clicked on")
            } else if (opened) {
                firstLine.push("opened")
            } else if (delivered) {
                firstLine.push("received")
            } else if (sent) {
                firstLine.push("was sent")
            } else {
                firstLine.push("was not sent")
            }
            if (bulkEmail) {
                firstLineIsHtml = true
                const subject = bulkEmail.get("bulk_email__subject")
                const id = bulkEmail.get("bulk_email_id")

                if (subject && id) {
                    firstLine.push(
                        `<a href="${DjangIO.app.models.QuorumDataType.bulk_email.profile}${id}/">${subject}</a>`,
                    )
                }
                // if the email was sent from an assignment, we want to display the
                // correct user / email it was sent from
                const sentFromAssignment = bulkEmail.get("bulk_email__send_from_assignments")
                const sender = sentFromAssignment
                    ? bulkEmail.get("assigned_sender_name")
                    : bulkEmail.get("bulk_email__from_name")

                const senderEmail = sentFromAssignment
                    ? bulkEmail.get("assigned_sender_email")
                    : bulkEmail.get("bulk_email__reply_to_address")

                if (sender && senderEmail) {
                    firstLine.push(`from <a href="mailto:${senderEmail}">${sender}</a>`)
                }
            }
        }

        return firstLine.join(" ")
    }

    const getSecondLine = () => {
        const secondLine = []

        if (email) {
            secondLine.push(email)
        }

        const initialOpens = datum.get("initial_opens")
        if (initialOpens >= 0) {
            secondLine.push(`${initialOpens} Initial open${initialOpens !== 1 ? "s" : ""}`)
        }

        const opens = datum.get("opens")
        if (opens >= 0) {
            secondLine.push(`${opens} Open${opens !== 1 ? "s" : ""}`)
        }

        const clicks = datum.get("clicks")
        if (clicks >= 0) {
            secondLine.push(`${clicks} Click${clicks !== 1 ? "s" : ""}`)
        }

        const date = datum.get("sent_datetime") || datum.get("created")
        if (date) {
            secondLine.push(`Sent ${parseDateTime(date)}`)
        }

        const firstOpen = datum.get("first_open")
        if (firstOpen) {
            secondLine.push(`First Opened on ${parseDateTime(firstOpen)}`)
        }

        return secondLine.join(" | ")
    }

    const getThirdLine = () => {
        if (bulkEmail) {
            const htmlContent = bulkEmail.get("bulk_email__html_content") || bulkEmail.get("bulk_email__text")

            if (htmlContent && stringContainsHTMLMarkup(htmlContent)) {
                thirdLineIsHtml = true
                dangerouslyInsertThirdLine = true
                return htmlContent
            }
        }

        return undefined
    }

    const getSecondLineDS = () => {
        const secondLine = []

        const date = datum.get("sent_datetime") || datum.get("created")
        if (date) {
            secondLine.push({
                ...getSecondLineIcon("date"),
                value: `Sent ${parseDateTime(date)}`,
            })
        }

        const emailLine = []
        if (email) {
            emailLine.push(email)
        }

        const initialOpens = datum.get("initial_opens")
        if (initialOpens >= 0) {
            emailLine.push(`${initialOpens} Initial open${initialOpens !== 1 ? "s" : ""}`)
        }

        const opens = datum.get("opens")
        if (opens >= 0) {
            emailLine.push(`${opens} Open${opens !== 1 ? "s" : ""}`)
        }

        const clicks = datum.get("clicks")
        if (clicks >= 0) {
            emailLine.push(`${clicks} Click${clicks !== 1 ? "s" : ""}`)
        }

        const firstOpen = datum.get("first_open")
        if (firstOpen) {
            emailLine.push(`First Opened on ${parseDateTime(firstOpen)}`)
        }

        if (emailLine.length) {
            secondLine.push({
                ...getSecondLineIcon("envelope"),
                value: emailLine.join(", "),
            })
        }

        return secondLine
    }

    return {
        hasNoProfile: true,
        iconName: DjangIO.app.models.QuorumDataType.send_email.icon.replace("fa-", ""),

        firstLineData: getFirstLine(),
        secondLineData: getSecondLine(),
        thirdLineData: getThirdLine(),
        firstLineIsHtml,
        thirdLineIsHtml,
        dangerouslyInsertThirdLine,

        secondLineDS: getSecondLineDS(),
    }
}

export const sendSMSInlineSelector = (datum) => {
    let firstLineIsHtml = false
    let thirdLineIsHtml = false
    let dangerouslyInsertThirdLine = false

    const bulkSMS = datum.get("bulk_sms")
    const bulk_sms_description = datum.get("bulk_sms_description")
    const bulk_sms_send_time = datum.get("bulk_sms_send_time")
    const associated_number = datum.get("associated_number")
    const supporter_name = datum.get("supporter_name")

    const getFirstLine = () => {
        const firstLine = []

        const name = supporter_name || associated_number
        const supporterId = datum.get("supporter_id")

        if (name) {
            if (supporterId) {
                firstLineIsHtml = true
                firstLine.push(
                    `<a href="${DjangIO.app.models.QuorumDataType.supporter.profile}${supporterId}"/>${name}</a>`,
                )
            } else {
                firstLine.push(name)
            }
            if (bulkSMS) {
                firstLineIsHtml = true
                const id = datum.get("bulk_sms_id")
                if (id) {
                    firstLine.push(
                        // @ts-expect-error this may be an actual bug - subject isn't declared anywhere?
                        `<a href="${DjangIO.app.models.QuorumDataType.bulk_sms.profile}${id}/">${subject}</a>`,
                    )
                }
            }
        }

        return firstLine.join(" ")
    }

    const getSecondLine = () => {
        const secondLine = []
        if (bulk_sms_description) {
            secondLine.push(bulk_sms_description)
        }
        const date = datum.get("send_time") || datum.get("created")
        if (date) {
            secondLine.push(`Sent ${parseDateTime(date)}`)
        }

        return secondLine.join(" | ")
    }

    const getThirdLine = () => {
        if (bulkSMS) {
            const htmlContent = datum.get("message_content")
            if (htmlContent && stringContainsHTMLMarkup(htmlContent)) {
                thirdLineIsHtml = true
                dangerouslyInsertThirdLine = true
                return htmlContent
            }

            return datum.get("message_content")
        }

        return undefined
    }

    const getSecondLineDS = () => {
        const line = []
        if (bulk_sms_description) {
            line.push(bulk_sms_description)
        }
        const date = bulk_sms_send_time || datum.get("created")
        if (date) {
            line.push(`Sent ${parseDateTime(date)}`)
        }

        const secondLine = []

        secondLine.push({ value: line.join(" | ") })

        return secondLine
    }

    return {
        hasNoProfile: true,
        iconName: DjangIO.app.models.QuorumDataType.send_sms.icon.replace("fa-", ""),

        firstLineData: getFirstLine(),
        secondLineData: getSecondLine(),
        thirdLineData: getThirdLine(),
        firstLineIsHtml,
        thirdLineIsHtml,
        dangerouslyInsertThirdLine,

        secondLineDS: getSecondLineDS(),
    }
}

export const replyEmailInlineSelector = (datum) => {
    let dangerouslyInsertThirdLine = false
    let firstLineIsHtml = false

    const related_fields = datum.getIn(["_extra", "related_fields"])

    const personId = related_fields.get("send_email__person_id")
    const personName = related_fields.get("send_email__person__name")

    const supporterId = related_fields.get("send_email__supporter_id")
    const supporterName = related_fields.get("send_email__supporter__name")
    const supporterOrg = related_fields.get("send_email__supporter__public_organization__name")

    const recipientName = related_fields.get("send_email__recipient_name")
    const fromName = related_fields.get("bulk_email__from_name")

    const getReplyDataType = () => {
        if (personId) {
            return DjangIO.app.models.QuorumDataType.person.profile
        } else if (supporterId) {
            return DjangIO.app.models.QuorumDataType.supporter.profile
        }
    }

    const replyId = personId || supporterId
    const replyName = personName || supporterName || recipientName
    const replyOrg = supporterOrg

    const getNameOrg = () => {
        const nameOrg = []

        if (replyName) {
            nameOrg.push(replyName)
        }

        if (replyOrg) {
            nameOrg.push(`(${replyOrg})`)
        }

        return nameOrg.join(" ")
    }

    const getFirstLine = () => {
        const firstLine = []
        const toAddresses = []

        const forwardAddress = datum.get("forward_address")
        const toAddress = datum.get("to_address")
        const fromAddress = datum.get("from_address")

        if (replyId) {
            firstLine.push(`Reply from <a href="${getReplyDataType()}${replyId}/">${getNameOrg()}</a>`)
            firstLineIsHtml = true
        } else if (fromAddress) {
            firstLine.push(`Reply from <a href="mailto://${fromAddress}">${fromAddress}</a>`)
            firstLineIsHtml = true
        }

        if (toAddress && fromName) {
            toAddresses.push(`to <a href="mailto://${toAddress}">${fromName}</a>`)
            firstLineIsHtml = true
        } else if (toAddress) {
            toAddresses.push(toAddress)
        } else if (fromName) {
            toAddresses.push(fromName)
        }

        if (forwardAddress) {
            toAddresses.push(`(forwarded to <a href="mailto://${forwardAddress}">${forwardAddress}</a>)`)
            firstLineIsHtml = true
        }

        if (toAddresses.length) {
            firstLine.push(toAddresses.join(" "))
        }

        return firstLine.join(" ")
    }

    const getSecondLine = () => {
        const secondLine = []
        const subject = datum.get("subject")
        const created = datum.get("created")
        const fromAddress = datum.get("from_address")

        if (fromAddress && replyId) {
            secondLine.push(fromAddress)
        }

        if (subject) {
            secondLine.push(subject)
        }

        if (created) {
            secondLine.push(`Replied ${parseDateTime(created)}`)
        }

        return secondLine.join(" | ")
    }

    const getThirdLine = () => {
        const html = datum.get("html_content")

        if (stringContainsHTMLMarkup(html)) {
            dangerouslyInsertThirdLine = true
        }

        return html
    }

    return {
        hasNoProfile: true,
        iconName: DjangIO.app.models.QuorumDataType.reply_email.icon.replace("fa-", ""),

        firstLineData: getFirstLine(),
        secondLineData: getSecondLine(),
        thirdLineData: getThirdLine(),
        firstLineIsHtml,
        dangerouslyInsertThirdLine,
    }
}

export const campaignInlineSelector = (datum) => {
    const getSecondLine = () => {
        const secondLine = []
        const campaignType = datum.get("campaign_type")
        if (campaignType) {
            secondLine.push(DjangIO.app.grassroots.campaign.types.CampaignType.by_value(campaignType).label || "")
        }

        const numActions = datum.get("number_actions")
        if (numActions) {
            secondLine.push(`Actions: ${numActions}`)
        }

        const numParticipants = datum.get("number_participants")
        if (numParticipants) {
            secondLine.push(`Participants: ${numParticipants}`)
        }

        const userDateCreated = []

        const creator = datum.getIn(["creator", "name"])
        if (creator) {
            userDateCreated.push(`Created by ${creator}`)
        }

        const created = datum.get("created")
        if (created) {
            userDateCreated.push(parseDateTime(created))
        }

        secondLine.push(userDateCreated.join(" on "))

        return secondLine.join(" | ")
    }

    const getIconProps = () => {
        // Returns the correct props for whichever icon should be used
        const campaignType = datum.get("campaign_type")
        if (campaignType) {
            const campaignTypeEnum = DjangIO.app.grassroots.campaign.types.CampaignType.by_value(campaignType)

            // Check for font awesome 5 icons
            if (campaignTypeEnum.new_icon) {
                return { fa5IconName: campaignTypeEnum.new_icon, fa5IconFamily: campaignTypeEnum.icon_family }
            }

            // Check for font awesome 4 icons
            if (campaignTypeEnum.icon) {
                return { iconName: campaignTypeEnum.icon.replace("fa-", "") }
            }
        }

        // Default to the campaign QDT icon
        const defaultIcon = DjangIO.app.models.QuorumDataType.campaign.icon
        return { iconName: defaultIcon.replace("fa-", "") }
    }

    return {
        ...getIconProps(),
        firstLineData: datum.get("name"),
        secondLineData: getSecondLine(),
        thirdLineData: datum.get("short_description"),
    }
}

export const actionCenterSettingsInlineSelector = (datum) => {
    const getIconName = () => {
        return DjangIO.app.models.QuorumDataType.campaign.icon.replace("fa-", "")
    }

    return {
        iconName: getIconName(),

        firstLineData: datum.get("name"),
        secondLineData: datum.get("organization"),
        thirdLineData: datum.get("welcome_message"),
    }
}

export const regulationInlineSelector = (datum) => {
    const generalRegStatus = DjangIO.app.reg.types.GeneralRegStatus
    const snippets = datum.get("snippets")

    let firstLineIsHtml = false
    let thirdLineIsHtml = false

    if (snippets && snippets.size > 0) {
        thirdLineIsHtml = true
    }

    const getEnactedClass = () => {
        const generalStatus = datum.get("general_status")

        return (
            generalStatus === generalRegStatus.adopted.value ||
            generalStatus === generalRegStatus.adopted_with_changes.value
        )
    }

    const getEnactedText = () => {
        const detailedStatus = datum.get("detailed_status")
        const enactedText = []

        if (detailedStatus) {
            const detailedStatusEnum = DjangIO.app.reg.types.DetailedRegStatus.by_value(detailedStatus)
            enactedText.push(detailedStatusEnum.label)

            if (
                detailedStatus === DjangIO.app.reg.types.DetailedRegStatus.open_for_comment.value ||
                detailedStatus === DjangIO.app.reg.types.DetailedRegStatus.comment_period_extended.value
            ) {
                const commentsClosed = datum.get("comment_close_date")
                if (commentsClosed) {
                    enactedText.push(`until ${parseDate(commentsClosed)}`)
                }
            }
        }

        return enactedText.join(" ")
    }

    const getRegion = () => {
        const region = datum.get("region")
        if (region) {
            return DjangIO.app.models.Region.by_value(region).region_name
        }

        return undefined
    }

    const getSecondLine = () => {
        const secondLine = []

        const regType = datum.get("reg_type")
        if (regType) {
            secondLine.push(`${DjangIO.app.reg.types.RegType.by_value(regType).label} Regulation`)
        }

        const agencies = datum.getIn(["_extra", "enhanced_agency_name"])
        if (agencies && agencies.size > 0) {
            secondLine.push(agencies.map((v) => v).join(", "))
        }

        const datePreProposed = datum.get("pre_proposal_date")
        const dateProposed = datum.get("proposal_date")

        if (datePreProposed) {
            secondLine.push(`Pre-Proposal Date ${parseDate(datePreProposed)}`)
        } else if (dateProposed) {
            secondLine.push(`Proposal Date ${parseDate(dateProposed)}`)
        }

        const effectiveDate = datum.get("effective_date")
        if (effectiveDate) {
            secondLine.push(`Effective Date ${parseDate(effectiveDate)}`)
        }

        const expirationDate = datum.get("expiration_date")
        if (expirationDate) {
            secondLine.push(`Expiration Date ${parseDate(expirationDate)}`)
        }

        const updated = datum.get("major_actions")
        const detailedStatusDate = datum.get("detailed_status_date")
        if (detailedStatusDate) {
            secondLine.push(`Last Updated ${parseDate(detailedStatusDate)}`)
        } else if (updated && updated.size > 0) {
            const updatedArr = updated.toJS()
            secondLine.push(`Date of Last Action ${parseDate(updatedArr[updatedArr.length - 1].date_string)}`)
        }

        const wordCount = datum.get("word_count")
        if (wordCount) {
            secondLine.push(`${wordCount} Words`)
        }

        return secondLine.join(" | ")
    }

    const getPdf = () => {
        const regulationTexts = datum.getIn(["_extra", "pdf_url"])

        if (regulationTexts) {
            return regulationTexts
        }

        return undefined
    }

    const firstLine = datum.get("title")

    if (stringContainsHTMLMarkup(firstLine)) {
        firstLineIsHtml = true
    }

    const getSecondLineDS = () => {
        const secondLine = [
            {
                ...getSecondLineIcon("status"),
                value: `Status: ${getEnactedText()}`,
            },
        ]

        const regType = datum.get("reg_type")
        if (regType) {
            secondLine.push({
                ...getSecondLineIcon("type"),
                value: `Regulation Type: ${DjangIO.app.reg.types.RegType.by_value(regType).label}`,
            })
        }

        const issues = getIssues(datum.getIn(["_extra", "issues"]))
        if (issues) {
            secondLine.push({
                ...getSecondLineIcon("issues"),
                value: issues,
            })
        }

        const agencies = datum.getIn(["_extra", "enhanced_agency_name"])
        if (agencies && agencies.size > 0) {
            secondLine.push({
                ...getSecondLineIcon("organizations"),
                value: agencies.map((v) => v).join(", "),
            })
        }

        const datePreProposed = datum.get("pre_proposal_date")
        const dateProposed = datum.get("proposal_date")
        if (datePreProposed) {
            secondLine.push({
                ...getSecondLineIcon("date"),
                value: `Pre-Proposal Date ${parseDate(datePreProposed)}`,
            })
        } else if (dateProposed) {
            secondLine.push({
                ...getSecondLineIcon("date"),
                value: `Proposal Date ${parseDate(dateProposed)}`,
            })
        }

        const effectiveDate = datum.get("effective_date")
        if (effectiveDate) {
            secondLine.push({
                ...getSecondLineIcon("date"),
                value: `Effective Date ${parseDate(effectiveDate)}`,
            })
        }

        const expirationDate = datum.get("expiration_date")
        if (expirationDate) {
            secondLine.push({
                ...getSecondLineIcon("date"),
                value: `Expiration Date ${parseDate(expirationDate)}`,
            })
        }

        const updated = datum.get("major_actions")
        const detailedStatusDate = datum.get("detailed_status_date")
        if (detailedStatusDate) {
            secondLine.push({
                ...getSecondLineIcon("date"),
                value: `Last Updated ${parseDate(detailedStatusDate)}`,
            })
        } else if (updated && updated.size > 0) {
            const updatedArr = updated.toJS()
            secondLine.push({
                ...getSecondLineIcon("date"),
                value: `Date of Last Action ${parseDate(updatedArr[updatedArr.length - 1].date_string)}`,
            })
        }

        return secondLine
    }

    return {
        achievedStatuses: datum.get("timeline_statuses"),
        enactedText: getEnactedText(),
        hasBeenEnacted: getEnactedClass(),
        href: `/regulation/${datum.get("id")}/`,
        model: DjangIO.app.models.QuorumDataType.regulation.value,
        pdfUrl: getPdf(),
        region: getRegion(),
        showRegion: true,
        snippets: snippets && snippets.size > 0 ? snippets.toJS() : undefined,

        firstLineData: firstLine,
        secondLineData: getSecondLine(),
        firstLineIsHtml,
        thirdLineIsHtml,

        secondLineDS: getSecondLineDS(),
        timelineEnum: DjangIO.app.reg.types.DetailedRegStatus,
        timelineStatuses: datum.get("timeline_statuses") && datum.get("timeline_statuses").toJS(),
    }
}

export const regulationCommentInlineSelector = (datum) => {
    let firstLineIsHtml = false

    const getFirstLine = () => {
        const firstLine = []

        const extra = datum.get("_extra")
        const relatedObjects = extra.get("related_objects")

        const title = datum.get("title")
        if (title) {
            firstLine.push(title)
        }

        const regDocumentId = relatedObjects.get("document_id")
        const regDocumentTitle = relatedObjects.get("document__title")
        const regulationId = relatedObjects.get("regulation_id")
        const regulationTitle = relatedObjects.get("regulation__title")
        const docketId = relatedObjects.get("docket_id")
        const docketTitle = relatedObjects.get("docket__title")

        if (regDocumentTitle) {
            firstLine.push(
                `on <a href="${DjangIO.app.models.QuorumDataType.document.profile}${regDocumentId}/">${regDocumentTitle}</a>`,
            )
        }
        if (regulationTitle) {
            firstLine.push(
                `on <a href="${DjangIO.app.models.QuorumDataType.regulation.profile}${regulationId}/">${regulationTitle}</a>`,
            )
        }
        if (docketTitle) {
            firstLine.push(
                `(<a href="${DjangIO.app.models.QuorumDataType.docket.profile}${docketId}/">${docketTitle}</a>)`,
            )
        }

        return firstLine.join(" ")
    }

    const getSecondLine = () => {
        const secondLine = []

        // commented out because there is currently only one RegCommentSourceType
        // const sourceType = datum.get("source_type")
        // if (sourceType) {
        //     secondLine.push(DjangIO.app.reg.types.RegCommentSourceType.by_value(sourceType).label)
        // }

        const commenterName = datum.get("commenter_name")
        if (commenterName) {
            secondLine.push(commenterName)
        }

        const organizationName = datum.get("organization_name")
        if (organizationName) {
            secondLine.push(organizationName)
        }

        const dateReceived = datum.get("received_by_agency_datetime")
        if (dateReceived) {
            secondLine.push(`Received ${parseDate(dateReceived)}`)
        }

        const datePosted = datum.get("posted_by_agency_datetime")
        if (datePosted) {
            secondLine.push(`Posted ${parseDate(datePosted)}`)
        }

        const category = datum.get("category")
        if (category) {
            secondLine.push(category)
        }

        return secondLine.join(" | ")
    }

    const { newLine: thirdLine, isHtml: thirdLineIsHtml } = checkLineBreakHtml(datum.get("comment_text"))

    const firstLine = getFirstLine()
    if (stringContainsHTMLMarkup(firstLine)) {
        firstLineIsHtml = true
    }

    return {
        hasNoProfile: true,
        // uncomment when we add the regcomment profile
        // href: `/reg_comment/${datum.get("id")}/`,
        model: DjangIO.app.models.QuorumDataType.reg_comment.value,
        regCommentPdfAttachments: datum
            .get("attachment_data")
            .toJS()
            // add iff s3_url ends with .pdf
            .filter((attachment) => attachment.s3_url.substring(attachment.s3_url.length - ".pdf".length) === ".pdf"),
        firstLineData: firstLine,
        secondLineData: getSecondLine(),
        thirdLineData: thirdLine,
        firstLineIsHtml,
        thirdLineIsHtml,
    }
}

export const voteResultInlineSelector = (datum) => {
    let firstLineIsHtml = false
    let secondLineIsHtml = false

    const voteResult = datum.get("vote_result_type")
    const relatedFields = datum.getIn(["_extra", "related_fields"])

    const getIconName = () => {
        if (voteResult) {
            return DjangIO.app.vote.models.VoteResultType.by_value(voteResult).icon.replace("fa-", "")
        }
        return "check-circle-o"
    }

    const getFirstLine = () => {
        const firstLine = []

        const personName = datum.get("name")
        if (personName) {
            firstLine.push(
                `<a href="${DjangIO.app.models.QuorumDataType.person.profile}${datum.get(
                    "person",
                )}/">${personName}</a>`,
            )
            firstLineIsHtml = true
        }

        const voteResultEnum = DjangIO.app.vote.models.VoteResultType.by_value(voteResult)
        if (
            voteResultEnum !== DjangIO.app.vote.models.VoteResultType.missed &&
            voteResultEnum !== DjangIO.app.vote.models.VoteResultType.present
        ) {
            firstLine.push(`Voted ${voteResultEnum.label}`)
        } else if (DjangIO.app.vote.models.VoteResultType.missed) {
            firstLine.push(`${voteResultEnum.label} the Vote`)
        } else if (DjangIO.app.vote.models.VoteResultType.present) {
            firstLine.push(`was ${voteResultEnum.label} at the Vote`)
        }

        if (voteResult) {
            if (relatedFields.get("bill_id")) {
                firstLineIsHtml = true
                firstLine.push(
                    `on <a href="${DjangIO.app.models.QuorumDataType.bill.profile}${relatedFields.get(
                        "bill_id",
                    )}/">${relatedFields.get("bill__title")}</a>`,
                )
            }
        }

        return firstLine.join(" ")
    }

    const getSecondLine = () => {
        const secondLine = []
        const question = datum.get("question")

        if (question) {
            secondLineIsHtml = true
            secondLine.push(
                `<a href="${DjangIO.app.models.QuorumDataType.vote.profile}${datum.get("vote")}/">${question}</a>`,
            )
        }

        if (relatedFields.get("amendment_id")) {
            secondLineIsHtml = true
            secondLine.push(
                `<a href="${DjangIO.app.models.QuorumDataType.amendment.profile}${relatedFields.get(
                    "amendment_id",
                )}/">${relatedFields.get("amendment__title")}</a>`,
            )
        }

        return secondLine.join(" | ")
    }

    return {
        hasNoProfile: true,
        iconName: getIconName(),
        voteResult: DjangIO.app.vote.models.VoteResultType.by_value(voteResult).slug,

        firstLineData: getFirstLine(),
        secondLineData: getSecondLine(),
        thirdLineData: parseDate(datum.get("vote_created")) || undefined,
        firstLineIsHtml,
        secondLineIsHtml,
    }
}

export const alertInlineSelector = (datum) => {
    let firstLineIsHtml = false
    let secondLineIsHtml = false

    const getIcon = () => {
        const dataType = datum.get("data_type")
        const qdt = DjangIO.app.models.QuorumDataType.by_value(dataType)

        if (qdt && qdt.icon) {
            return qdt.icon.replace("fa-", "")
        }

        return DjangIO.app.models.QuorumDataType.alert.icon.replace("fa-", "")
    }

    const getFirstLine = () => {
        const firstLine = []
        const title = datum.get("title")
        const alertGroupTitle = datum.getIn(["alert_group", "title"])

        if (title) {
            if (stringContainsHTMLMarkup(title)) {
                firstLineIsHtml = true
            }

            firstLine.push(title)
        }

        if (alertGroupTitle) {
            firstLine.push(alertGroupTitle)
        }

        return firstLine.join(" in ")
    }

    const getSecondLine = () => {
        const secondLine = []

        const paused = datum.get("paused")
        if (paused) {
            secondLine.push("Paused")
        }

        const isDefault = datum.get("default")
        const createdByArr = []

        if (isDefault) {
            createdByArr.push("Created by Quorum")
        }

        const creatorAndDate = []
        const creator = datum.get("creator")
        if (creator) {
            const creatorAndOrg = []
            const creatorEmail = creator.get("email")
            const creatorName = creator.get("name")

            if (Userdata.is_admin && creator) {
                secondLineIsHtml = true
                createdByArr.push(`<a href="mailto:${creatorEmail}/">${creatorName}</a>`)
            }
            if (createdByArr.length) {
                creatorAndOrg.push(createdByArr.join(" for "))
            }

            const orgName = creator.get("user_info__organization__name")
            const orgId = creator.get("user_info__organization__id")
            if (orgName && orgId) {
                secondLineIsHtml = true
                creatorAndOrg.push(
                    `<a href=${DjangIO.app.models.QuorumDataType.public_organization.profile}${orgId}/>${orgName}</a>`,
                )
            }

            if (creatorAndOrg.length) {
                creatorAndDate.push(creatorAndOrg.join(isDefault ? " for " : " in "))
            }
        }

        const created = datum.get("created")
        if (created) {
            creatorAndDate.push(`${parseDateTime(created)}`)
        }
        if (creatorAndDate.length) {
            secondLine.push(creatorAndDate.join(" on "))
        }

        const updated = datum.get("updated")
        if (updated) {
            secondLine.push(`Last Updated ${parseDateTime(updated)}`)
        }

        const frequency = datum.get("frequency")
        const sendTime = datum.get("send_time")
        if (frequency) {
            const frequencyArr = []
            frequencyArr.push(DjangIO.app.userdata.alerts.types.AlertFrequency.by_value(frequency).label)

            if (sendTime) {
                frequencyArr.push(parseTime(sendTime))
            }

            secondLine.push(`Updating ${frequencyArr.join(" at ")}`)
        }

        return secondLine.join(" | ")
    }

    const getThirdLine = () => {
        const thirdLine = []

        const deliveryMedium = datum.get("media")
        if (deliveryMedium && deliveryMedium.size && deliveryMedium.toJS) {
            thirdLine.push(parseEnumValues(deliveryMedium, DjangIO.app.userdata.alerts.types.DeliveryMedium, "label"))
        }

        const updateType = datum.get("update_types")
        if (updateType && updateType.size && updateType.toJS) {
            thirdLine.push(parseEnumValues(updateType, DjangIO.app.userdata.alerts.types.NewUpdateType, "search_label"))
        }

        return thirdLine.join(" | ")
    }

    return {
        iconName: getIcon(),

        firstLineData: getFirstLine(),
        secondLineData: getSecondLine(),
        thirdLineData: getThirdLine(),
        firstLineIsHtml,
        secondLineIsHtml,
    }
}

export const alertGroupInlineSelector = (datum) => ({
    iconName: "rss-square",
    firstLineData: datum.get("title"),
})

export const getFeedItemUnderlyingItemId = (quorumLink, qdt) => {
    if (quorumLink) {
        let replacementString = "/"
        if (qdt === DjangIO.app.models.QuorumDataType.note) {
            replacementString = "="
        }
        return parseInt(quorumLink.substring(quorumLink.lastIndexOf(replacementString) + 1))
    }

    return undefined
}

export const feedItemInlineSelector = (datum) => {
    const dataType = datum.get("data_type")
    const qdt = DjangIO.app.models.QuorumDataType.by_value(dataType)
    const regions = datum.get("regions")
    const snippets = datum.getIn(["item_cache", "snippets"])
    const hasSnippets = snippets && snippets.size > 0
    const parsedRegions =
        regions && regions.size && regions.toJS && parseEnumValues(regions, DjangIO.app.models.Region, "abbrev")

    // extras
    const extra = datum.get("_extra")
    const alert = extra && extra.get("alert")

    const feedItemCache = datum.get("big_cache")
    const prelabel =
        feedItemCache && feedItemCache.get("prelabel") && checkParseAlertString(feedItemCache.get("prelabel"))

    let firstLineIsHtml = false
    let secondLineIsHtml = false

    const getFirstLine = () => {
        const title = datum.get("title") && checkParseAlertString(datum.get("title"))
        const titleArray = []
        const prelabelArray = []

        const primaryLabel =
            feedItemCache &&
            feedItemCache.get("primary_label") &&
            checkParseAlertString(feedItemCache.get("primary_label"))

        if (prelabel) {
            if (stringContainsHTMLMarkup(prelabel)) {
                firstLineIsHtml = true
            }

            prelabelArray.push(prelabel)
        }

        if (prelabelArray.length) {
            titleArray.push(prelabelArray.join(" "))
        }

        if (title) {
            if (stringContainsHTMLMarkup(title)) {
                firstLineIsHtml = true
            }
            titleArray.push(title)
        } else if (primaryLabel) {
            if (stringContainsHTMLMarkup(primaryLabel)) {
                firstLineIsHtml = true
            }
            titleArray.push(primaryLabel)
        }

        return titleArray.join(": ")
    }

    const getSecondLine = () => {
        const secondLine = []

        const updateType = datum.get("update_type")

        if (updateType) {
            secondLine.push(DjangIO.app.userdata.alerts.types.NewUpdateType.by_value(updateType).search_label)
        }

        if (parsedRegions) {
            secondLine.push(parsedRegions)
        }

        if (alert) {
            const alertDefault = alert.get("alert__default")
            const alertTitle = alert.get("alert__title")
            const enhancedAlertTitle =
                alertTitle &&
                (dataType === DjangIO.app.models.QuorumDataType.bill.value && alertTitle.includes(prelabel)
                    ? prelabel
                    : alertTitle)

            const alertId = alert.get("alert_id")

            const creatorEmail = alert.get("alert__creator__email")
            const creatorName = alert.get("alert__creator__username")
            const creatorId = alert.get("alert__creator_id")
            const creatorArr = []

            if (alertDefault) {
                creatorArr.push("Created by Quorum")
            }
            if (creatorEmail && creatorName) {
                secondLineIsHtml = true
                creatorArr.push(`<a href="mailto:${creatorEmail}">${creatorName}</a>`)
            }
            if (creatorArr.length) {
                secondLine.push(creatorArr.join(" for "))
            }

            const ownerId = alert.get("user_id")
            const ownerEmail = alert.get("user__email")
            const ownerName = alert.get("user__username")
            if (ownerId && (creatorId !== ownerId || !creatorId)) {
                secondLineIsHtml = true
                secondLine.push(`<a href="mailto:${ownerEmail}">${ownerName}</a> (owner)`)
            }

            if (enhancedAlertTitle && alertId) {
                secondLineIsHtml = true
                secondLine.push(
                    `<a href="${DjangIO.app.models.QuorumDataType.alert.profile}${alertId}/">${enhancedAlertTitle}</a>`,
                )
            }

            const alertGroupTitle = alert.get("alert__alert_group__title")
            if (alertGroupTitle) {
                secondLine.push(alertGroupTitle)
            }

            const alertDataType = alert.get("alert__data_type")
            if (alertDefault && alertDataType == DjangIO.app.models.QuorumDataType.note.value) {
                if (datum.getIn(["big_cache", "fields"])) {
                    const value = datum.getIn(["big_cache", "fields"]).find((e) => e.get("label") == "Text:")
                    if (value && value.get("values")) {
                        const text = value.get("values").toJS()[0]
                        if (text) {
                            secondLineIsHtml = true
                            secondLine.push(replaceFeedItemMention(text))
                        }
                    }
                }
            }
        }

        const deliveryMedium = datum.get("media")
        const created = datum.get("created")
        if (created) {
            secondLine.push(`Sent ${parseDateTime(created)}`)
        }

        if (deliveryMedium && deliveryMedium.size && deliveryMedium.toJS) {
            secondLine.push(parseEnumValues(deliveryMedium, DjangIO.app.userdata.alerts.types.DeliveryMedium, "label"))
        }

        return secondLine.join(" | ")
    }

    let quorumLink =
        feedItemCache &&
        feedItemCache.get("quorum_link") &&
        feedItemCache.get("quorum_link").replace("https://www.quorum.us", "")

    const underlyingItemId = getFeedItemUnderlyingItemId(quorumLink, qdt)

    // some URLs in the big_cache return with the trailing '/', while some don't
    // many paths on the site will 404 if we do not include a '/'
    if (
        quorumLink &&
        // the last char is not '/' (breaks big_cache Public Organization field segue)
        quorumLink.length &&
        quorumLink[quorumLink.length - 1] !== "/" &&
        // the URI does not contain a query (breaks Note search segue)
        !quorumLink.includes("?")
    ) {
        quorumLink = `${quorumLink}/`
    }
    return {
        alertSnippets: hasSnippets ? snippets.toJS() : undefined,
        feedItemCache: feedItemCache && feedItemCache.toJS(),
        hasNoProfile: !(dataType && quorumLink),
        href: quorumLink,
        model: dataType,
        quorumDataType: qdt,
        underlyingItemId,

        firstLineData: getFirstLine(),
        secondLineData: getSecondLine(),
        firstLineIsHtml,
        secondLineIsHtml,
        thirdLineIsHtml: hasSnippets,
        regions: parsedRegions,
        created: parseDateTime(datum.get("created")),
    }
}

export const personRoleInlineSelector = (datum) => {
    const title = datum.get("title")
    const current = datum.get("current")
    const state = datum.get("state")

    const getFirstLine = () => {
        const firstLine = []

        if (title) {
            firstLine.push(title)
        }

        const secondaryTile = datum.get("leadership_title")
        if (secondaryTile) {
            firstLine.push(secondaryTile)
        }

        return firstLine.join(": ")
    }

    const getSecondLine = () => {
        const secondLine = []

        if (current) {
            secondLine.push("Current")
        }

        const roleType = datum.get("role_type")
        if (roleType) {
            secondLine.push(DjangIO.app.person.types.RoleType.by_value(roleType).label)
        }

        const region = datum.get("region")
        if (region) {
            secondLine.push(DjangIO.app.models.Region.by_value(region).label)
        }

        const personType = datum.get("person_type")
        if (personType) {
            secondLine.push(DjangIO.app.person.types.PersonType.by_value(personType).label)
        }

        const dateRange = []

        const start = datum.get("startdate")
        if (start) {
            dateRange.push(`${parseDate(start)}`)
        }

        const end = datum.get("enddate")
        if (end) {
            dateRange.push(`${parseDate(end)}`)
        }

        secondLine.push(dateRange.join(" - "))

        return secondLine.join(" | ")
    }

    const getThirdLine = () => {
        const fourthLine = []

        const publicOrganization = datum.get("public_organization")
        if (publicOrganization) {
            fourthLine.push(publicOrganization)
        }

        const address = datum.get("address")
        if (address) {
            fourthLine.push(address)
        }

        return fourthLine.join(", ")
    }

    return {
        disableRegionColon: !title,
        hasBeenEnacted: current,
        hasNoProfile: true,
        iconName: "history",
        region: state || undefined,
        showRegion: (state && state.length > 0) || false,

        firstLineData: getFirstLine(),
        secondLineData: getSecondLine(),
        thirdLineData: getThirdLine(),
    }
}

export const customDataInlineSelector = (datum) => {
    const getThirdLine = () => {
        const nonNumericFields = datum.get("non_numeric_fields")
        const numericFields = datum.get("numeric_fields")
        const combinedArray = nonNumericFields.concat(numericFields)

        const fieldArr = []

        if (combinedArray && combinedArray.size > 0) {
            combinedArray.forEach((typeStr) => {
                fieldArr.push(typeStr)
            })

            return fieldArr.join(" | ")
        }

        return undefined
    }
    return {
        iconName: "database",

        firstLineData: datum.get("category"),
        secondLineData: datum.get("is_batch_data") ? "Aggregate" : "Geographic",
        thirdLineData: getThirdLine(),
    }
}

export const issueInlineSelector = (datum) => {
    const getSecondLine = () => {
        const parents = datum.getIn(["_extra", "parents"])
        const fieldArr = []

        if (parents && parents.size > 0) {
            parents.forEach((parent) => {
                fieldArr.push(parent.get("name"))
            })

            return fieldArr.join(" | ")
        }

        return undefined
    }

    const icon = datum.get("icon")
    return {
        iconName: (icon && icon.replace("fa-", "")) || "sticky-note-o",

        firstLineData: datum.get("name"),
        secondLineData: getSecondLine(),
        isHalfWidth: true,
    }
}

export const stafferTitleInlineSelector = (datum) => ({
    iconName: "vcard",

    firstLineData: datum.get("title"),
    isHalfWidth: true,
})

export const stafferTermInlineSelector = (datum) => ({
    iconName: "sticky-note-o",

    firstLineData: datum.get("kw_responsibility"),
    isHalfWidth: true,
})

export const assignmentInlineSelector = (datum) => {
    const assignmentType = DjangIO.app.userdata.types.AssignmentType.by_value(datum.get("assignment_type"))
    const qdt = DjangIO.app.models.QuorumDataType.by_value(assignmentType.quorum_data_type)
    let firstLineIsHtml = false

    const getFirstLine = () => {
        const firstLine = []

        const extra = datum.get("_extra")
        const relatedObjects = extra.get("related_objects")
        const title = datum.get("title")

        const creatorUsername = relatedObjects.get("creator__username")
        const recipientUsername = relatedObjects.get("recipient__username")
        const recipientSupporterId = relatedObjects.get("recipient_supporter_id")
        const recipientSupporterName = relatedObjects.get("recipient_supporter__name")

        if (creatorUsername) {
            firstLine.push(`${creatorUsername} assigned`)
        }

        if (recipientUsername) {
            firstLine.push(recipientUsername)
        } else if (recipientSupporterId && recipientSupporterName) {
            firstLine.push(
                `<a href="${DjangIO.app.models.QuorumDataType.supporter.profile}${recipientSupporterId}">${recipientSupporterName}</a>`,
            )
            firstLineIsHtml = true
        }

        if (title) {
            firstLine.push(`to ${title}`)
        }

        return firstLine.join(" ")
    }

    const getSecondLine = () => {
        const secondLine = []

        if (qdt) {
            secondLine.push(qdt.singular)
        }

        const isPrimary = datum.get("is_primary")
        if (isPrimary) {
            secondLine.push("Primary")
        }

        const created = datum.get("created")
        if (created) {
            secondLine.push(`${parseDateTime(created)}`)
        }

        return secondLine.join(" | ")
    }

    return {
        hasNoProfile: true,
        iconName: qdt.icon.replace("fa-", ""),

        firstLineData: getFirstLine(),
        secondLineData: getSecondLine(),
        firstLineIsHtml,
    }
}

export const teamInlineSelector = (datum) => {
    const organization = datum.getIn(["_extra", "organization"])

    const getFirstLine = () => {
        const name = datum.get("name")
        return name
    }

    const getSecondLine = () => {
        const secondLine = []

        if (organization) {
            const orgName = organization.get("organization__name")

            if (orgName) {
                if (orgName) {
                    secondLine.push(orgName)
                }

                const defaultDomain = organization.get("organization__default_domain")
                if (defaultDomain) {
                    secondLine.push(defaultDomain)
                }

                return secondLine.join(" | ")
            }
        }

        return undefined
    }

    return {
        iconName: "users",

        firstLineData: getFirstLine(),
        secondLineData: getSecondLine(),
    }
}

export const statInlineSelector = (datum) => {
    let firstLine = datum.get("full_name")
    let secondLine = datum.get("category_full_name")
    const rawText = datum.get("description")

    if (rawText) {
        const labelArr = rawText.split("!!")
        firstLine = labelArr.pop()

        if (labelArr.length > 1) {
            secondLine = `${secondLine} | ${labelArr.pop()}`
        }

        while (labelArr.length > 1) {
            firstLine = `${firstLine} | ${labelArr.pop()}`
        }
    }

    return {
        iconName: "university",
        firstLineData: firstLine,
        secondLineData: secondLine,
        isHalfWidth: true,
        isSmallIcon: true,
    }
}

export const geoshapeInlineSelector = (datum) => {
    const region = datum.get("region")
    const regionName = region && DjangIO.app.models.Region.by_value(region).region_name
    const abbrev = datum.get("abbrev")
    const geoShapeType = datum.get("geo_shape_type")
    const typeLabel = geoShapeType && DjangIO.app.geography.types.GeoShapeType.by_value(geoShapeType).label

    const getSecondLine = () => {
        const secondLine = []
        if (regionName) {
            secondLine.push(regionName)
        } else if (abbrev) {
            // choose name or abbrev
            secondLine.push(abbrev)
        }
        if (typeLabel) {
            secondLine.push(typeLabel)
        }
        return secondLine.join(" | ")
    }

    return {
        iconName: "map",
        firstLineData: datum.get("name"),
        secondLineData: getSecondLine(),
        isHalfWidth: true,
        isSmallIcon: true,
    }
}

export const savedSearchInlineSelector = (datum) => {
    const searchQdt =
        DjangIO.app.models.QuorumDataType.by_value(datum.get("data_type")) ||
        DjangIO.app.models.QuorumDataType.saved_search
    const iconName = searchQdt && searchQdt.icon.split("fa-")[1]
    return {
        iconName,

        firstLineData: datum.get("humanized_text"),
        secondLineData: parseDateTime(datum.get("created")),
    }
}

export const liveTranscriptSectionInlineSelector = (datum) => {
    const thirdLineIsHtml = true

    const imageUrl = datum.get("image_url")
        ? generateStaticUrl(datum.get("image_url"))
        : generateStaticUrl(BLANK_AVATAR_PATH)

    const speakerName = datum.get("source_person_str")
        ? datum.get("source_person_str").replace(">>", "").replace("-- ", "")
        : "Unidentified Speaker"

    return {
        hasNoProfile: true,
        imagePath: imageUrl,

        firstLineData: speakerName,
        secondLineData: parseDateTime(datum.get("created_date_time")),
        thirdLineData: datum.get("text"),
        thirdLineIsHtml,
    }
}

export const bulkUploadFileInlineSelector = (datum) => {
    const uploadType = DjangIO.app.custom_data.types.BulkUploadType.by_value(datum.get("upload_type"))
    return {
        iconName: "upload",

        firstLineData: datum.get("name"),
        secondLineData: uploadType && uploadType.label,
        thirdLineData: parseDateTime(datum.get("created")),
    }
}

export const customEventParticipantInlineSelector = (datum, isProfile) => {
    const relatedFields = datum.getIn(["_extra", "related_fields"])

    const attendeeStatus =
        datum.get("attendee_status") &&
        DjangIO.app.custom_event.types.AttendeeStatus.by_value(datum.get("attendee_status")).label

    const invited = datum.get("has_been_invited")

    const rsvpStatus =
        datum.get("rsvp_status") && DjangIO.app.custom_event.types.RSVPStatus.by_value(datum.get("rsvp_status")).label

    let firstLineIsHtml = false
    let thirdLineIsHtml = false

    const getFirstLine = () => {
        const firstLine = []
        const hasEventHappened = moment(relatedFields.get("event__date_time")).isBefore(moment(moment.now()))
        const profileQdt = datum.get("data_type")

        const personName = relatedFields.get("person__name")
        if (personName) {
            firstLineIsHtml = true
            firstLine.push(
                `<a href="${DjangIO.app.models.QuorumDataType.by_value(profileQdt).profile}${relatedFields.get(
                    "person_id",
                )}/">${personName}</a>`,
            )
        }

        const supporterName = relatedFields.get("supporter__name") || relatedFields.get("supporter__email")
        if (supporterName) {
            firstLineIsHtml = true
            firstLine.push(
                `<a href="${DjangIO.app.models.QuorumDataType.supporter.profile}${relatedFields.get(
                    "supporter_id",
                )}/">${supporterName}</a>`,
            )
        }

        if (attendeeStatus) {
            firstLine.push(attendeeStatus)
        } else if (rsvpStatus) {
            firstLine.push(`rsvp'd ${rsvpStatus.toLowerCase()} to`)
        } else if (invited) {
            firstLine.push(
                hasEventHappened ? "was invited to and emailed about" : "has been invited to and emailed about",
            )
        } else {
            firstLine.push(
                hasEventHappened ? "was invited to but not emailed about" : "has been invited to but not emailed about",
            )
        }

        const eventName = relatedFields.get("event__name")
        if (eventName) {
            firstLineIsHtml = true
            firstLine.push(
                `<a href="${DjangIO.app.models.QuorumDataType.custom_event.profile}${relatedFields.get(
                    "event_id",
                )}/">${eventName}</a>`,
            )
        }

        return firstLine.join(" ")
    }

    const getSecondLine = () => {
        const secondLine = []

        const eventDate = relatedFields.get("event__date_time")
        if (eventDate) {
            secondLine.push(parseDateTime(eventDate))
        }

        if (attendeeStatus) {
            secondLine.push(attendeeStatus)
        }

        if (rsvpStatus) {
            secondLine.push(`Rsvp'd ${rsvpStatus}`)
        }

        const venueName = relatedFields.get("event__venue_name")
        if (venueName) {
            secondLine.push(venueName)
        }

        const address = relatedFields.get("event__address")
        if (address) {
            secondLine.push(address)
        }

        const dataType = datum.get("data_type")
        let qdt: DjangIOEnumTypes["QuorumDataType"] | DjangIOEnumTypes["PersonType"]
        qdt = dataType && DjangIO.app.models.QuorumDataType.by_value(dataType)

        if (!isProfile && qdt && qdt.label) {
            const personType = relatedFields.get("person__most_recent_person_type")
            if (personType) {
                qdt = DjangIO.app.person.types.PersonType.by_value(personType)
            }
            secondLine.push(qdt.label)
        }

        return secondLine.join(" | ")
    }

    const getThirdLine = () => {
        const internalDescription = relatedFields.get("event__internal_description")

        if (internalDescription) {
            if (stringContainsHTMLMarkup(internalDescription)) {
                thirdLineIsHtml = true
            }

            return internalDescription
        }

        return undefined
    }

    const getIconName = () => {
        if (!isProfile && relatedFields.get("supporter_id") && Userdata.isOnlyEUMode()) {
            return "user supporter-icon"
        }
        return DjangIO.app.models.QuorumDataType.custom_event_participant.icon.replace("fa-", "")
    }

    const getImagePath = () => {
        if (relatedFields.get("person_id")) {
            const personImageUrl = relatedFields.get("person__image_url")
            if (personImageUrl) {
                // officials
                return personImageUrl
            }
            // staffers
            return getImage(
                fromJS({
                    image_url: relatedFields.get("person__image_url"),
                    most_recent_region: relatedFields.get("most_recent_region"),
                }),
                BLANK_AVATAR_PATH,
                BLANK_AVATAR_PATH,
            )
        } else if (relatedFields.get("supporter_id")) {
            const supporterImageUrl = relatedFields.get("supporter__image_url")
            return supporterImageUrl && generateMediaUrl(supporterImageUrl)
        }

        return undefined
    }

    const getStateAbbrev = () => {
        const stateAbbrev = relatedFields.get("supporter__state__abbrev")

        if (stateAbbrev) {
            return stateAbbrev.toLowerCase().replace(/^\s+|\s+$/g, "")
        }
        return "us"
    }

    return {
        abbrev: !isProfile ? relatedFields.get("supporter_id") && getStateAbbrev() : undefined,
        hasNoProfile: true,
        iconName: getIconName(),
        imageBorderRule: !isProfile
            ? relatedFields.get("person_id") &&
              DjangIO.app.person.types.Party.by_value(
                  relatedFields.get("person__most_recent_party"),
              ).label.toLowerCase()
            : undefined,
        imagePath: !isProfile ? getImagePath() : undefined,
        stateIcon: !isProfile
            ? relatedFields.get("supporter_id") &&
              !isAllRegionsExceptUS &&
              (stateKeys[relatedFields.get("supporter__state__abbrev")] || stateKeys.US)
            : undefined,

        firstLineData: getFirstLine(),
        secondLineData: getSecondLine(),
        thirdLineData: getThirdLine(),
        firstLineIsHtml,
        thirdLineIsHtml,
    }
}

export const agencyInlineSelector = (datum) => {
    const region = datum.get("region")

    let firstLineIsHtml = false

    const firstLine = datum.get("enhanced_agency_name")
    if (stringContainsHTMLMarkup(firstLine)) {
        firstLineIsHtml = true
    }

    return {
        iconName: DjangIO.app.models.QuorumDataType.project.icon.replace("fa-", ""),
        region: region && DjangIO.app.models.Region.by_value(region).region_name,
        showRegion: Boolean(region),

        firstLineData: firstLine,
        firstLineIsHtml,
    }
}

export const docketInlineSelector = (datum) => {
    const region = datum.get("region")
    let secondLineIsHtml = false
    let thirdLineIsHtml = false

    const getSecondLine = () => {
        const secondLine = []

        const docketType = datum.get("docket_type")
        if (docketType) {
            secondLine.push(DjangIO.app.reg.types.DocketType.by_value(docketType).label)
        }

        const docketId = datum.get("docket_id")
        if (docketId) {
            if (stringContainsHTMLMarkup(docketId)) {
                secondLineIsHtml = true
            }
            secondLine.push(docketId)
        }

        const created = datum.get("created")
        if (created) {
            secondLine.push(`${parseDate(created)}`)
        }

        return secondLine.join(" | ")
    }

    const getThirdLine = () => {
        const description = datum.get("description")

        if (stringContainsHTMLMarkup(description)) {
            thirdLineIsHtml = true
        }

        return description
    }

    const getSecondLineDS = () => {
        const secondLine = []

        const created = datum.get("created")
        if (created) {
            secondLine.push({
                ...getSecondLineIcon("date"),
                value: `${parseDate(created)}`,
            })
        }

        const docketType = datum.get("docket_type")
        if (docketType) {
            secondLine.push({
                ...getSecondLineIcon("type"),
                value: DjangIO.app.reg.types.DocketType.by_value(docketType).label,
            })
        }

        const docketId = datum.get("docket_id")
        if (docketId) {
            secondLine.push({
                ...getSecondLineIcon("info"),
                value: docketId,
            })
        }

        return secondLine
    }

    return {
        href: `/docket/${datum.get("id")}/`,
        iconName: DjangIO.app.models.QuorumDataType.docket.icon.replace("fa-", ""),
        model: DjangIO.app.models.QuorumDataType.docket.value,
        region: region && DjangIO.app.models.Region.by_value(region).region_name,
        showRegion: true,

        firstLineData: datum.get("title"),
        secondLineData: getSecondLine(),
        thirdLineData: getThirdLine(),
        firstLineIsHtml: stringContainsHTMLMarkup(datum.get("title")),
        secondLineIsHtml,
        thirdLineIsHtml,

        secondLineDS: getSecondLineDS(),
    }
}

export const trackingDashboardListInlineSelector = (datum) => {
    const qdt = DjangIO.app.models.QuorumDataType.by_value(datum.get("dataType"))
    return {
        firstLineData: datum.get("name"),
        secondLineData: `${qdt && qdt.label ? qdt.label : ""}${qdt ? " " : ""}List`,
    }
}

export const articleInlineSelector = (datum) => {
    const authorStr = datum.getIn(["author", "name"])
    const orgStr = datum.getIn(["source", "name"]) || "No Source"
    const dateStr = datum.get("estimated_published_date")
    const adjustedDateStr = dateStr && moment(dateStr).local().format("MMMM Do, YYYY, h:mm:ss a")
    const secondLineData = [...(authorStr ? [authorStr] : []), orgStr, adjustedDateStr].join(" | ")

    // Custom inline html to right-align (per product) and prevent license terms from covering the list, priority, and issue buttons.
    const fourthLineData = `\
        <div style="\
            font-size: 10px;\
            text-align: right;\
            padding-right: 100px;\
        ">\
            ${MEDIA_TERMS_AND_CONDITIONS_FOOTER}
        </div>\
    `
    const firstLineData = datum.get("title")
    const firstLineIsHtml = stringContainsHTMLMarkup(firstLineData)
    const snippets = datum.get("snippets")
    const sourceType = datum.getIn(["source", "media_type"])

    const immutableLicense = datum.get("license")
    const license = immutableLicense && immutableLicense.toJS()
    const isLicensed =
        license &&
        Array.isArray(license) &&
        license.length > 0 &&
        DjangIO.app.media_monitoring.types.MediaLicense.by_value(license[0]).licensed_content
    const textToShow = isLicensed ? datum.get("content") : datum.get("extract")
    const shouldShowFrame = () => shareableSelectShouldShowFrame(datum.get("x_frame_options"))

    const getSecondLineDS = () => {
        const secondLine = []

        if (adjustedDateStr) {
            secondLine.push({
                ...getSecondLineIcon("date"),
                value: adjustedDateStr,
            })
        }

        const authorOrgLine = []
        if (authorStr) {
            authorOrgLine.push(authorStr)
        }
        if (orgStr) {
            authorOrgLine.push(orgStr)
        }
        if (authorOrgLine.length) {
            secondLine.push({
                ...getSecondLineIcon("newspaper"),
                value: authorOrgLine.join(", "),
            })
        }

        return secondLine
    }

    return {
        expandOnClick: () =>
            sendUserActionPost({
                userActionType: DjangIO.app.useractions.types.UserActionType.search_media_article_inline_clicked,
                description: "Expanded Media Article Inline",
                quorumDataType: DjangIO.app.models.QuorumDataType.media.value,
                uri: DjangIO.app.media_monitoring.models.Article.resourceUriFromId(datum.get("id")),
            }),
        expandedIFrameUrl: !isLicensed && shouldShowFrame() && datum.get("lexis_url"),
        hideExpand: !isLicensed && !shouldShowFrame(),
        href: `/article/${datum.get("id")}/`,
        iconName: DjangIO.app.media_monitoring.types.MediaSourceType.by_value(sourceType).icon,
        model: DjangIO.app.models.QuorumDataType.media.value,
        showRegion: true,
        snippets: snippets && snippets.size > 0 ? snippets.toJS() : undefined,

        firstLineData,
        secondLineData,
        fourthLineData: isLicensed && fourthLineData,
        thirdLineData: lineBreaks(textToShow),
        firstLineIsHtml,
        thirdLineIsHtml: true,
        fourthLineIsHtml: true,
        showFourthLineIfExpanded: true,

        secondLineDS: getSecondLineDS(),
    }
}

export const articleTopicInlineSelector = (datum) => ({
    firstLineData: datum.get("topic"),
    iconName: DjangIO.app.models.QuorumDataType.article_topic.icon.replace("fa-", ""),
    model: DjangIO.app.models.QuorumDataType.article_topic.value,
})

export const politicalCommitteeInlineSelector = (datum) => {
    const { PoliticalCommitteeType, FecParty, FecCommitteeCode, FecCandidateStatus } = DjangIO.app.pac.types

    const committeeName = datum.get("name")
    const fecCommitteeId = datum.get("fec_committee_id")
    const addressStreet = [datum.get("addr_street1")]
    const addressStreet2 = datum.get("addr_street2")
    const addressZip = datum.get("addr_zip")
    const party = datum.get("party") && FecParty.by_value(datum.get("party")).label
    const street = addressStreet.join(" ")
    const addressCity = datum.get("addr_city")
    const addressState = datum.get("addr_state")
    const candidateName = datum.get("candidate_name")
    const districtName = datum.get("district_name")
    const candidateStatus =
        datum.get("candidate_status") && FecCandidateStatus.by_value(datum.get("candidate_status")).label
    const committeeCode =
        datum.get("fec_committee_code") && FecCommitteeCode.by_value(datum.get("fec_committee_code")).label

    if (addressStreet2) {
        addressStreet.push(addressStreet2)
    }
    const committeeType =
        datum.get("committee_type") &&
        DjangIO.app.pac.types.PoliticalCommitteeType.by_value(datum.get("committee_type")).label

    const getThirdLine = () => {
        if (getIsMobile()) {
            return ""
        }

        if (
            committeeType === PoliticalCommitteeType.candidate_committee.label ||
            committeeType === PoliticalCommitteeType.state_candidate_committee.label
        ) {
            const candidateDisplayList = [candidateName, districtName, addressState, committeeCode, party]

            // filter out undefined values and then join with |, ex. Democratic Party | District 1 | ...
            return candidateDisplayList.filter((x) => x).join(" | ")
        }

        return `${party} | ${street}, ${addressCity}, ${addressState} ${addressZip}`
    }

    const getSecondLineDS = () => {
        const secondLine = []

        if (committeeType) {
            secondLine.push({
                ...getSecondLineIcon("building"),
                listLabel: "Type",
                value: committeeType,
            })
        }

        if (
            committeeType === PoliticalCommitteeType.candidate_committee.label ||
            committeeType === PoliticalCommitteeType.state_candidate_committee.label
        ) {
            secondLine.push({
                ...getSecondLineIcon("user-circle"),
                listLabel: "Candidate",
                // filter out falsy values and then join with |, ex. Democratic Party | District 1 | ...
                value: [candidateName, districtName, addressState, committeeCode, party, candidateStatus]
                    .filter(Boolean)
                    .join(" | "),
            })
        } else {
            secondLine.push({
                ...getSecondLineIcon("user-circle"),
                listLabel: "Location",
                value: `${party} | ${street}, ${addressCity}, ${addressState} ${addressZip}`,
            })
        }

        return secondLine
    }

    return {
        firstLineData: committeeName,
        firstLineIsHtml: stringContainsHTMLMarkup(committeeName),
        secondLineData: committeeType,
        thirdLineData: getThirdLine(),
        iconName: "usd",

        secondLineDS: getSecondLineDS(),
    }
}

export const transactionInlineSelector = (datum) => {
    const { TransactionType, TransactionMethodType, TransactionSpecialType } = DjangIO.app.ledger.types

    const amount = datum.get("amount")
    const entity = datum.get("entity_associated_with_transaction")
    const pacronym = datum.get("ledger_settings_pacronym")
    const transactionType = datum.get("transaction_type")
    const occurredDate = datum.get("occurred_date")
    const transactionMethod = datum.get("transaction_method")
    const referenceNumber = datum.get("reference_number")
    const account = datum.get("bank_account_for_transaction")
    const override = datum.get("override") // todo
    const reconciled = datum.get("is_reconciled")
    const voided = datum.get("is_voided")
    const special_type = datum.get("transaction_special_type")
    const description = datum.get("description")
    const supporter = datum.get("supporter")
    const publicOrganization = datum.get("public_organization")
    const politicalCommittee = datum.get("committee")
    const election = datum.get("election_name")

    const isReceipt = isReceiptTransaction(transactionType)

    const isDisbursement = isDisbursementTransaction(transactionType)

    const getFirstLine = () => {
        const link = generateTransactionHyperlink(entity, supporter, publicOrganization, politicalCommittee)

        if (isReceipt) {
            return `${pacronym} received ${amount} from ${link || entity}`
        }
        // else it is a disbursement - TransactionType.money_given
        return `${pacronym} gave ${amount} to ${link || entity}`
    }

    const getSecondLine = () => {
        const secondLine = []

        if (occurredDate) {
            secondLine.push(moment(occurredDate).local().format("MMMM Do, YYYY"))
        }
        if (transactionMethod) {
            secondLine.push(TransactionMethodType.by_value(transactionMethod).label)
        }
        if (referenceNumber) {
            secondLine.push(referenceNumber)
        }
        if (account) {
            secondLine.push(account)
        }
        if (election) {
            secondLine.push(election)
        }

        return secondLine.join(" | ")
    }

    const createDTVData = () => {
        const dtvData = []

        if (description) {
            dtvData.push({
                title: `Purpose of ${TransactionType.by_value(transactionType).pac_label}`,
                value: description,
            })
        }
        dtvData.push({
            title: "Override",
            value: override ? "Yes" : "No",
        })
        dtvData.push({
            title: "Reconciled",
            value: reconciled ? "Yes" : "No",
        })
        if (isDisbursement) {
            dtvData.push({
                title: "Void",
                value: voided ? "Yes" : "No",
            })
        }
        if (isDisbursement) {
            dtvData.push({
                title: "Redesignation",
                value: special_type === TransactionSpecialType.redesignation.value ? "Yes" : "No",
            })
        }
        if (isReceipt) {
            dtvData.push({
                title: "Reattribution",
                value: special_type === TransactionSpecialType.reattribution.value ? "Yes" : "No",
            })
        }
        if (isReceipt) {
            dtvData.push({
                title: "Partnership Attribution",
                value: special_type === TransactionSpecialType.partnership_attribution.value ? "Yes" : "No",
            })
        }
        dtvData.push({
            title: "Custom Fields",
            value: "TODO",
        })

        return createDataSections(dtvData)
    }

    const getIcon = () => {
        const transactionType = datum.get("transaction_type")
        if (isReceipt) {
            return "receipt"
        }
        // else it should be a Disbursement or Disbursement Refund
        return "money-check-alt"
    }

    const getSecondLineDS = () => {
        const secondLine = []

        if (occurredDate) {
            secondLine.push({
                ...getSecondLineIcon("date"),
                listLabel: "Date",
                value: moment(occurredDate).local().format("MMMM Do, YYYY"),
            })
        }
        const transactionLine = []
        if (transactionMethod) {
            transactionLine.push(TransactionMethodType.by_value(transactionMethod).label)
        }
        if (account) {
            transactionLine.push(account)
        }
        if (transactionLine.length) {
            secondLine.push({
                ...getSecondLineIcon("usd-square"),
                listLabel: "Transaction",
                value: transactionLine.join(", "),
            })
        }

        if (referenceNumber) {
            secondLine.push({
                ...getSecondLineIcon("info"),
                listLabel: "Reference Number",
                value: referenceNumber,
            })
        }

        if (election) {
            secondLine.push({
                ...getSecondLineIcon("podium"),
                listLabel: "Election",
                value: election,
            })
        }

        return secondLine
    }

    return {
        href: getTransactionEditUrl({
            id: datum.get("id"),
            ledger_settings_id: datum.get("ledger_settings_id"),
        }),
        firstLineData: getFirstLine(),
        secondLineData: getSecondLine(),
        firstLineIsHtml: true,
        // enabling hideAllThirdLineData will render the See More section
        // hideAllThirdLineData: true,
        dataTableViewData: createDTVData(),
        fa5IconName: getIcon(),
        hasNoProfile: false, // false so the transaction card is clickable

        secondLineDS: getSecondLineDS(),
    }
}

export const pacClassicPacOfficeInlineSelector = (datum) => {
    const getFirstLine = () => {
        const name = datum.get("name")
        const juris = datum.get("juris")
        return `${name} | ${juris}`
    }

    return {
        hasNoProfile: true,
        firstLineData: getFirstLine(),
        firstLineIsHtml: false,
        secondLineData: "",
        secondLineIsHtml: false,
    }
}

export const pacClassicPacOrganizationInlineSelector = (datum) => {
    const { PacClassicPacLimitType } = DjangIO.app.pac_classic.types

    const getThirdLine = () => {
        const thirdLine = []
        const pacLimitType = datum.get("pac_limit_type")
        const party = datum.get("party")

        const getAddress = () => {
            const address = []

            const addressLine1 = datum.get("address_line_1")
            if (addressLine1) {
                address.push(addressLine1)
            }

            const addressLine2 = datum.get("address_line_2")
            if (addressLine2) {
                address.push(addressLine2)
            }

            return address.join(" ")
        }
        const address = getAddress()

        if (
            [
                PacClassicPacLimitType.federal_candidate_committee,
                PacClassicPacLimitType.jfc,
                PacClassicPacLimitType.state_candidate_committee,
                PacClassicPacLimitType.local_candidate_committee,
            ].includes(pacLimitType)
        ) {
            // Candidate First Name Candidate Last Name | District, Office State | Office | Party | Incumbent. Challenger, Open Seat
            const candidateFirstName = datum.get("candidate_first_name")
            if (candidateFirstName) {
                thirdLine.push(candidateFirstName)
            }

            const candidateLastName = datum.get("candidate_last_name")
            if (candidateLastName) {
                thirdLine.push(candidateLastName)
            }

            const district = datum.get("office_district")
            if (district) {
                thirdLine.push(district)
            }

            const officeState = datum.get("office_state")
            if (officeState) {
                thirdLine.push(officeState)
            }

            const extra = datum.get("_extra")
            const foreignKeyNames = extra.get("foreign_key_names")
            const officeName = foreignKeyNames.get("office__name")
            if (officeName) {
                thirdLine.push(officeName)
            }

            const party = datum.get("party")
            if (party) {
                thirdLine.push(DjangIO.app.pac_classic.types.PacClassicPartyType.by_value(party).label)
            }

            const isIncumbent = datum.get("isIncumbent")
            if (isIncumbent) {
                thirdLine.push(isIncumbent)
            }
        } else if (
            [
                PacClassicPacLimitType.national_party_committee,
                PacClassicPacLimitType.federal_pac_ssf,
                PacClassicPacLimitType.state_pac_ssf,
                PacClassicPacLimitType.state_party_committee,
                PacClassicPacLimitType.state_party_committee,
                PacClassicPacLimitType.local_party_committee,
            ].includes(pacLimitType)
        ) {
            // Party | Address
            if (party) {
                thirdLine.push(DjangIO.app.pac_classic.types.PacClassicPartyType.by_value(party).label)
            }

            if (address) {
                thirdLine.push(address)
            }
        } else {
            // Address
            thirdLine.push(address)
        }

        return thirdLine.join(" | ")
    }

    return {
        href: paths.pacOrganization.replace(":id", datum.get("id")),
        hasNoProfile: false,
        firstLineData: datum.get("organization_name"),
        firstLineIsHtml: false,
        secondLineData:
            datum.get("pac_limit_type") && PacClassicPacLimitType.by_value(datum.get("pac_limit_type")).label,
        secondLineIsHtml: false,
        thirdLineData: getThirdLine(),
        thirdLineIsHtml: false,
    }
}

export const pacClassicPacTransactionInlineSelector = (datum) => {
    const extra = datum.get("_extra")
    const foreignKeyNames = extra.get("foreign_key_names")
    const pacName = foreignKeyNames.get("pac_classic_pac__name")

    const { PacClassicMetaTransactionType, PacClassicPacReportCode } = DjangIO.app.pac_classic.types
    const reportCode = datum.get("pac_report_code")

    const getFirstLine = () => {
        const metaTransactionType = PacClassicPacReportCode.by_value(reportCode).meta_transaction_type
        const displayAmount = datum.get("display_amount")
        const entityName = datum.get("entity_name")
        const isVoid = datum.get("is_void")

        const metaTransactionVerbMap = {
            [PacClassicMetaTransactionType.allocation.value]: ["allocated", "to"],
            [PacClassicMetaTransactionType.disbursement.value]: ["gave", "to"],
            [PacClassicMetaTransactionType.disbursement_refund.value]: ["received a refund of", "from"],
            [PacClassicMetaTransactionType.receipt.value]: ["received", "from"],
            [PacClassicMetaTransactionType.receipt_refund.value]: ["gave a refund of", "to"],
            // Unknown meta types are not valid, but in case of invalid data we still need to display something
            [PacClassicMetaTransactionType.unknown.value]: ["had a transaction of", "with"],
        }

        const [verb, toOrFrom] = metaTransactionVerbMap[metaTransactionType]

        return `${isVoid ? "(VOID)" : ""} ${pacName} ${verb} ${displayAmount} ${toOrFrom} ${entityName}`
    }

    const getSecondLine = () => {
        const transactionDate = datum.get("transaction_date")
        const transactionDateString = moment(transactionDate).format("MMMM Do, YYYY")

        const reportCode = datum.get("pac_report_code")
        const reportCodeLabel = PacClassicPacReportCode.by_value(datum.get("pac_report_code")).label
        const reportCodeString = `${reportCodeLabel} (${reportCode})`

        const pacTransactionId = datum.get("pac_transaction_id")

        return `${transactionDateString} | ${reportCodeString} | Transaction ID: #${pacTransactionId}`
    }

    return {
        href: paths.transaction.replace(":id", datum.get("id")),
        hasNoProfile: false,
        firstLineData: getFirstLine(),
        firstLineIsHtml: false,
        secondLineData: getSecondLine(),
        secondLineIsHtml: false,
    }
}

export const campaignFinanceReceiptInlineSelector = (datum) => {
    const amount = datum.get("amount")
    const entity = datum.get("donor_name")
    const pacronym = datum.get("super_committee_recipient_name")
    const occurredTime = datum.get("occurred_time")
    const referenceNumber = datum.get("id")
    const account = datum.get("bank_account_for_transaction")
    const election = datum.get("election_cycle")
    const name = datum?.get("name")

    const getFirstLine = () => {
        if (!!pacronym && !!entity) {
            return `${pacronym} received $${amount || 0} from ${entity}`
        }

        return `${entity || name}`
    }

    const getSecondLine = () => {
        const secondLine = []

        if (occurredTime) {
            secondLine.push(moment(occurredTime).local().format("MMMM Do, YYYY, h:mm:ss a"))
        }

        if (referenceNumber) {
            secondLine.push(referenceNumber)
        }
        if (account) {
            secondLine.push(account)
        }
        if (election) {
            secondLine.push(election)
        }

        return secondLine.join(" | ")
    }

    const createDTVData = () => {
        const dtvData = []

        dtvData.push({
            title: "Amount",
            value: amount,
        })

        return createDataSections(dtvData)
    }

    const getIcon = () => {
        return "receipt"
    }

    return {
        firstLineData: getFirstLine(),
        secondLineData: getSecondLine(),
        firstLineIsHtml: true,
        // enabling hideAllThirdLineData will render the See More section
        // hideAllThirdLineData: true,
        dataTableViewData: createDTVData(),
        fa5IconName: getIcon(),
        hasNoProfile: false, // false so the transaction card is clickable

        secondLineDS: [],
    }
}

export const campaignFinanceReceiptDisabledInlineSelector = (datum) => {
    const data = campaignFinanceReceiptInlineSelector(datum)
    // @ts-expect-error this may be an actual bug - isHighlightable isn't used here?
    data.isHighlightable = false
    data.hasNoProfile = true
    return data
}

export const campaignFinanceDisbursementInlineSelector = (datum) => {
    const entity = datum?.get("spender_name")
    const name = datum?.get("disburser_name") || datum?.get("name")
    const amount = datum.get("amount")
    const pacronym = datum.get("recipient_name") || datum.get("recipient_title")

    const getFirstLine = () => {
        if (!!pacronym && !!amount && !!entity) {
            return `${entity || name} disbursed $${amount} to ${pacronym}`
        }

        return `${entity || name}`
    }

    const getSecondLine = () => {
        return ""
    }

    const getIcon = () => {
        return "receipt"
    }

    return {
        firstLineData: getFirstLine(),
        secondLineData: getSecondLine(),
        firstLineIsHtml: true,
        // enabling hideAllThirdLineData will render the See More section
        // hideAllThirdLineData: true,
        fa5IconName: getIcon(),
        hasNoProfile: false, // false so the transaction card is clickable
    }
}

export const campaignFinanceDisbursementDisabledInlineSelector = (datum) => {
    const data = campaignFinanceDisbursementInlineSelector(datum)
    // @ts-expect-error this may be an actual bug - isHighlightable isn't used here?
    data.isHighlightable = false
    data.hasNoProfile = true
    return data
}

export const electionInlineSelector = (datum) => {
    const name = datum.get("name")
    const electionType =
        datum.get("election_type") && DjangIO.app.events.types.ElectionType.by_value(datum.get("election_type")).label

    return {
        hasNoProfile: true,
        fa5IconName: "podium",
        fa5IconFamily: "far",
        firstLineData: `${name}`,
        secondLineData: electionType,
    }
}

export const beatInlineSelector = (datum) => ({
    iconName: "rss",
    firstLineData: datum.get("name"),
    secondLineData: datum.get("description"),
    isHalfWidth: true,
})

export const bulkSMSInlineSelector = (datum) => {
    let thirdLineIsHtml = false
    let dangerouslyInsertThirdLine = false

    const getSecondLine = () => {
        const secondLine = []

        const creator = datum.getIn(["_extra", "creator"])

        if (creator) {
            secondLine.push(creator)
        }

        const time = datum.get("scheduled_time") || datum.get("sent_time")
        if (time) {
            secondLine.push(parseDateTime(time))
        }

        const opens = datum.get("opens")
        if (opens) {
            secondLine.push(`${opens} Open${opens > 1 ? "s" : ""}`)
        }

        return secondLine.join(" | ")
    }

    const getThirdLine = () => {
        const text = datum.get("text")

        if (text) {
            if (stringContainsHTMLMarkup(text)) {
                thirdLineIsHtml = true
                dangerouslyInsertThirdLine = true
            }
            return text
        }

        return undefined
    }

    return {
        href: `/texting/details/${datum.get("id")}/`,
        iconName: DjangIO.app.models.QuorumDataType.bulk_sms.icon.replace("fa-", ""),

        firstLineData: datum.get("description"),
        secondLineData: getSecondLine(),
        thirdLineData: getThirdLine(),
        thirdLineIsHtml,
        dangerouslyInsertThirdLine,
    }
}

/* eslint-enable */
